import { SidebarShapes } from '../utils/sidebar-shape.js';
import { b as _defineProperty } from '../_rollupPluginBabelHelpers-1525680e.js';

class MxSideBar {
  constructor(_graph, container) {
    _defineProperty(this, "createEdge", (shape, icon, x, y) => {
      const cell = new mxCell(null, new mxGeometry(0, 0, x, y), shape);
      cell.geometry.setTerminalPoint(new mxPoint(0, y), true);
      cell.geometry.setTerminalPoint(new mxPoint(x, 0), false);
      cell.geometry.relative = true;
      cell.edge = true;
      let img = this.addSidebarItem(this.graph, toolbar, cell, icon);
      img.enabled = true;
    });
    _defineProperty(this, "createVertex", (shape, icon, x, y) => {
      let cell = new mxCell(null, new mxGeometry(0, 0, x, y), shape);
      cell.setVertex(true);
      let img = this.addSidebarItem(this.graph, toolbar, cell, icon);
      img.enabled = true;
    });
    _defineProperty(this, "addSidebarItem", (graph, toolbar, prototype, image) => {
      const funct = function (graph, evt) {
        const model = graph.getModel();
        const root = model.getRoot();
        const objectLayer = root.children[0];
        graph.stopEditing(false);
        const pt = graph.getPointForEvent(evt);
        const vertex = graph.getModel().cloneCell(prototype);
        vertex.geometry.x = pt.x;
        vertex.geometry.y = pt.y;
        vertex.id = `cell-${Date.now()}`;
        if (vertex.isVertex()) {
          graph.setSelectionCells(graph.importCells([vertex], 0, 0, objectLayer));
        } else {
          graph.setSelectionCells(graph.importCells([vertex], pt.x, pt.y, objectLayer));
        }
      };
      const img = toolbar.addMode(null, image, function (evt) {
        funct(graph, evt);
      });
      mxUtils.makeDraggable(img, graph, funct);
      return img;
    });
    this.graph = _graph;
    this.sidebarContainer = container;
  }
  init() {
    new mxRubberband(this.graph);
    this.graph.setConnectable(true);
    toolbar = new mxToolbar(this.sidebarContainer);
    toolbar.enabled = false;
    SidebarShapes.forEach(shape => {
      if (shape.type === 'edge') {
        this.createEdge(shape.style, shape.icon, shape.x, shape.y);
      } else {
        this.createVertex(shape.style, shape.icon, shape.x, shape.y);
      }
    });
  }
}

export { MxSideBar };
