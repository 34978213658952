import { _ as _objectSpread2 } from '../../../_rollupPluginBabelHelpers-4e4a2de3.js';
import '@danfoss/etui-sm-xml';
import '../../../utils/get-array.js';
import '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import React from 'react';
import isEqual from 'lodash.isequal';
import 'react-device-detect';
import '../utils/get-device-info.js';
import { getSegmentationValues } from '../utils/get-segmentation-values.js';
import { fetchProxyUserData } from '../actions/fetch-proxy-user-data.js';

function useSegmentationValues(_ref) {
  let {
    units = null,
    user = null,
    width = null,
    orientation = null,
    proxyConfig = null,
    proxyUserApiUrl = null,
    pollingInterval = 5000
  } = _ref;
  const [segmentationValues, setSegmentationValues] = React.useState(null);
  const updateSegmentationIfChanges = newSegmentationValues => {
    const changes = !isEqual(newSegmentationValues, segmentationValues);
    changes && setSegmentationValues(newSegmentationValues);
  };
  const timeoutId = React.useRef();
  React.useEffect(() => {
    const updateSegmentationData = () => {
      const segmentationData = getSegmentationValues(units, user, {
        width,
        orientation
      });
      if (proxyConfig !== null && proxyConfig !== void 0 && proxyConfig.token && proxyUserApiUrl) {
        fetchProxyUserData(proxyConfig, proxyUserApiUrl).then(userData => {
          updateSegmentationIfChanges(_objectSpread2(_objectSpread2({}, segmentationData), userData));
        }).catch(() => {
          updateSegmentationIfChanges(_objectSpread2(_objectSpread2({}, segmentationData), {}, {
            mail: 'N/A'
          }));
        });
      } else {
        updateSegmentationIfChanges(segmentationData);
      }
      timeoutId.current = setTimeout(() => updateSegmentationData(), pollingInterval);
    };
    updateSegmentationData();
    return () => clearTimeout(timeoutId.current);
  }, [units, user, width, orientation, proxyConfig, segmentationValues]);
  return {
    segmentationValues
  };
}

export { useSegmentationValues };
