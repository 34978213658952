import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@danfoss/etui-system';
import useSWR from 'swr';
import {
  addFieldRecursively,
  flattenData,
  IconSize,
  TreeviewSelect,
  useResponsive,
} from '@danfoss/etui-core';
import { useXmlResource, useAuth, useUnit } from '@danfoss/etui-sm/context';
import { getDeviceIdAndDeviceTypeByEquipmentId } from 'utils';
import { fetchGroupedDevicesByDeviceType } from 'actions';
import { routePaths } from 'routePaths';
import { EquipmentTreeviewSelectItem } from './EquipmentTreeviewSelectItem';

const FIELD_TO_DISPLAY = 'fullName';

function EquipmentTreeviewSelect() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const { push } = useHistory();

  const { deviceType } = getDeviceIdAndDeviceTypeByEquipmentId(equipmentId);
  const { units } = useUnit();

  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();

  const [processedData, setProcessedData] = React.useState([]);
  const [preselectedItem, setPreselectedItem] = React.useState(null);

  const { screenIsAtMost } = useResponsive({
    xs: parseInt(theme.breakpoints[0], 10),
  });
  const isXsView = screenIsAtMost('xs');

  const customStyles = React.useMemo(
    () => ({
      wrapper: {
        marginLeft: `${theme.spacing.md}px`,
        height: isXsView ? '50px' : '60px',
        width: '250px',
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        borderRight: `1px solid ${theme.palette.grey[100]}`,
      },
      selectContent: {
        backgroundColor: '#484848',
        color: theme.palette.common.white,
        width: '180px',
      },
      main: {
        backgroundColor: theme.palette.black[80],
      },
      options: {
        backgroundColor: '#484848',
        color: theme.palette.common.white,
        top: isXsView ? '50px' : '60px',
        maxHeight: '80vh',
        overflow: 'auto',
      },
    }),
    [isXsView],
  );

  const onSelectItem = item => {
    const id = `${item.deviceType}_${item.id}`;
    if (item !== preselectedItem) {
      setPreselectedItem(item);
      push(`${routePaths.pages.equipment.listPath}/${id}`);
    }
  };

  const { data = [] } = useSWR(
    () => (units.length ? [xmlBackendURL, deviceType, user, units] : null),
    fetchGroupedDevicesByDeviceType,
    {
      shouldRetryOnError: false,
    },
  );

  React.useEffect(() => {
    if (data?.length) {
      const preselectedItemId = equipmentId.split('_').pop();
      const preprocessedData = addFieldRecursively(
        data,
        'items',
        FIELD_TO_DISPLAY,
        (item, name) => (name ? `${name} / ${item.name}` : name + item.name),
      );
      const allItems = flattenData(preprocessedData);
      const result = allItems.find(item => item.id === preselectedItemId);
      setProcessedData(preprocessedData);
      setPreselectedItem(result);
    }
  }, [data.length, equipmentId]);

  return (
    preselectedItem && (
      <TreeviewSelect
        customStyles={customStyles}
        iconProps={{ size: 16 as IconSize, color: theme.palette.black[10] }}
        data={processedData}
        displayKey={FIELD_TO_DISPLAY}
        label={t('t963')}
        onSelectItem={onSelectItem}
        preselectedItem={preselectedItem}
        rowTemplate={props => <EquipmentTreeviewSelectItem {...props} />}
      />
    )
  );
}

export { EquipmentTreeviewSelect };
