import * as React from 'react';
import { Modal, useResponsive } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { P } from '@danfoss/etui-system-elements';
import { AddressBook } from 'types';
import {
  getAddressBookObject,
  verifyAddressBookPassword,
} from 'utils/addressbook-helpers';
import { usePasswordPrompt } from 'pages/LoginPage/hooks';
import { decrypt } from 'utils/crypto-helpers';
import {
  showSuccessNotification,
  showErrorNotification,
} from 'pages/LoginPage/utils';

const getFormatedDate = () => {
  const now = new Date().toISOString();
  const [date, time] = now.split('T');
  return `${date.replace(/-/g, '_')}_${time.slice(0, -5).replace(/:/g, '_')}`;
};

export const ExportModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });
  const isMd = screenIsAtMost('md', ['portrait', 'landscape']);

  const {
    setPromptMessage,
    onPromptResolve,
    showPasswordPrompt,
    closePasswordPrompt,
  } = usePasswordPrompt();

  const exportWithPurePasswords = () => {
    const { version, data }: AddressBook = getAddressBookObject();
    const handleAddressBookPwdInput = (addressBookPassword: string) => {
      try {
        verifyAddressBookPassword(addressBookPassword);
        const sites = data.map(site => ({
          ...site,
          password: decrypt(site.password, addressBookPassword),
        }));
        downloadFile({ version, hash: '', data: sites });
        closePasswordPrompt();
        finishExport();
      } catch (e) {
        showErrorNotification(theme, t, e);
      }
    };

    setPromptMessage('t3489');
    onPromptResolve(handleAddressBookPwdInput);
    showPasswordPrompt();
  };

  const exportWithEncryptedPasswords = () => {
    const addressBookObject: AddressBook = getAddressBookObject();
    downloadFile(addressBookObject);
    finishExport();
  };

  const downloadFile = (addressBook: AddressBook) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(addressBook),
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `address_book_${getFormatedDate()}.json`;
    link.click();
  };

  const finishExport = () => {
    showSuccessNotification(theme, t, 't3651');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Header title={t('t3463')} />}
      actionButtons={[
        {
          testId: 'export-pure-passwords-btn',
          variant: 'primary',
          onClick: exportWithPurePasswords,
          children: t('t3504'),
        },
        {
          testId: 'export-encrypted-passwords-btn',
          variant: 'primary',
          onClick: exportWithEncryptedPasswords,
          children: t('t3505'),
        },
      ]}
      style={{
        content: {
          margin: isMd && theme.spacing.md,
        },
      }}
    >
      <P>{t('t3503')}</P>
    </Modal>
  );
};
