import { b as _defineProperty } from '../../_rollupPluginBabelHelpers-1525680e.js';

class Subscribable {
  constructor() {
    _defineProperty(this, "subscribers", new Set());
  }
  subscribe(cb) {
    this.subscribers.add(cb);
    return {
      unsubscribe: () => {
        this.subscribers.delete(cb);
      }
    };
  }
  notify() {
    this.subscribers.forEach(cb => cb());
  }
}

export { Subscribable };
