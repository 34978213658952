import { c as _defineProperty, _ as _objectSpread2 } from '../../../_rollupPluginBabelHelpers-4e4a2de3.js';

class AccordionStore {
  constructor(_ref) {
    let {
      expanded: _expanded = [],
      allowMultipleExpanded = false,
      allowZeroExpanded = false
    } = _ref;
    _defineProperty(this, "toggleExpanded", uuid => {
      var _this$expanded;
      if (this.isItemDisabled(uuid)) {
        return this;
      }
      const isExpanded = this.isItemExpanded(uuid);
      if (!isExpanded) {
        return this.augment({
          expanded: this.allowMultipleExpanded ? [...this.expanded, uuid] : [uuid]
        });
      }
      return this.augment({
        expanded: ((_this$expanded = this.expanded) === null || _this$expanded === void 0 ? void 0 : _this$expanded.filter(expandedUuid => expandedUuid !== uuid)) || []
      });
    });
    /*
     * From the spec:
     *
     * “If the accordion panel associated with an accordion header is visible,
     * and if the accordion does not permit the panel to be collapsed, the
     * header button element has aria-disabled set to true.”
     */
    _defineProperty(this, "isItemDisabled", uuid => {
      const isExpanded = this.isItemExpanded(uuid);
      const isOnlyOneExpanded = this.expanded.length === 1;
      return Boolean(isExpanded && !this.allowZeroExpanded && isOnlyOneExpanded);
    });
    _defineProperty(this, "isItemExpanded", uuid => {
      return this.expanded.indexOf(uuid) !== -1;
    });
    _defineProperty(this, "getPanelAttributes", uuid => {
      const expanded = this.isItemExpanded(uuid);
      return {
        role: this.allowMultipleExpanded ? undefined : 'region',
        'aria-hidden': this.allowMultipleExpanded ? !expanded : undefined,
        'aria-labelledby': this.getButtonId(uuid),
        id: this.getPanelId(uuid),
        hidden: expanded ? undefined : true
      };
    });
    // eslint-disable-next-line class-methods-use-this
    _defineProperty(this, "getHeadingAttributes", _uuid => {
      return {
        role: 'heading'
      };
    });
    _defineProperty(this, "getButtonAttributes", uuid => {
      const expanded = this.isItemExpanded(uuid);
      const disabled = this.isItemDisabled(uuid);
      return {
        id: this.getButtonId(uuid),
        'aria-disabled': disabled,
        'aria-expanded': expanded,
        'aria-controls': this.getPanelId(uuid),
        role: 'button',
        tabIndex: 0
      };
    });
    // eslint-disable-next-line class-methods-use-this
    _defineProperty(this, "getPanelId", uuid => `accordion__panel-${uuid}`);
    // eslint-disable-next-line class-methods-use-this
    _defineProperty(this, "getButtonId", uuid => `accordion__heading-${uuid}`);
    _defineProperty(this, "augment", args => {
      return new AccordionStore(_objectSpread2({
        expanded: this.expanded,
        allowMultipleExpanded: this.allowMultipleExpanded,
        allowZeroExpanded: this.allowZeroExpanded
      }, args));
    });
    this.expanded = _expanded;
    this.allowMultipleExpanded = allowMultipleExpanded;
    this.allowZeroExpanded = allowZeroExpanded;
  }
}

export default AccordionStore;
