import * as React from 'react';
import { ConfigurationListItem, Unit } from '@danfoss/etui-sm-xml';
import { SaveFunc } from './ConfigurationListItemEdit';
import { ConfigurationListItem as ListItem } from './ConfigurationListItem';
import { ConfigurationListItemAddress } from './ConfigurationItemContentList';
import { getHtmlId } from './utils';

export type ConfigurationItemNonVirtualizedListProps = {
  menuId: string;
  isUpdating?: boolean;
  isFailed: boolean;
  list: ConfigurationListItem[];
  getItemIsAuthorized: (item: ConfigurationListItem) => boolean;
  addresses: ConfigurationListItemAddress[];
  unit: Unit;
  tabIsEditable: boolean;
  onSave: SaveFunc;
  itemRenderer?: (...args: any[]) => React.ReactNode;
  resetConfigSwrCache: () => void;
};

const ConfigurationItemNonVirtualizedList = ({
  menuId,
  isUpdating,
  isFailed,
  list,
  getItemIsAuthorized,
  addresses,
  unit,
  tabIsEditable,
  onSave,
  itemRenderer,
  resetConfigSwrCache,
}: ConfigurationItemNonVirtualizedListProps) => {
  if (isFailed || !list?.length) return null;

  return (
    <>
      {list.map((item, index) => (
        <ListItem
          key={unit.unit_addr + index + menuId + item.li}
          menuId={menuId}
          index={index}
          item={item}
          addresses={addresses}
          unit={unit}
          isEditable={tabIsEditable}
          isAuthorized={item ? getItemIsAuthorized(item) : false}
          isUpdating={isUpdating}
          htmlId={getHtmlId(item, index)}
          onSave={onSave}
          itemRenderer={itemRenderer}
          resetConfigSwrCache={resetConfigSwrCache}
        />
      ))}
    </>
  );
};

export { ConfigurationItemNonVirtualizedList };
