import '../constants/session-storage-constants.js';
import '../constants/xml-tag-constant.js';
import { AUTHORIZATION_HEADER, AUTH_TYPE } from '../types/Auth.js';
import '../types/Metadata.js';
import '../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import { b64UriToObj, objToB64Uri } from '../helpers/xml-helpers.js';
import { trimUrlEnding } from '../helpers/trim-url-ending.js';
import { updateUnitsMetadata } from '../helpers/metadata-helpers.js';
import { createProxyUrl } from '../helpers/proxy-helpers.js';
import { getProxyConfig } from '../proxy-config.js';

const METADATA_PATH = 'metadata.json';
const metadataTimeoutMs = 3000;
const addMetadataToEncodedUrl = (url, urlEndPoint) => {
  const queryParamSeparator = 'q=';
  const [route, queryParam] = url.includes(queryParamSeparator) || !urlEndPoint ? url.split(queryParamSeparator) : urlEndPoint.split(queryParamSeparator);
  const initialParams = b64UriToObj(queryParam);
  initialParams.metadata = true;
  const updatedUrl = objToB64Uri(initialParams);
  return [route, updatedUrl].join(queryParamSeparator);
};
const fetchMetadataFile = async url => {
  const proxyConfig = getProxyConfig();
  const {
    proxyEnabled,
    token
  } = proxyConfig;
  const processedUrl = trimUrlEnding(url);
  const proxyUrl = createProxyUrl(proxyConfig, url);
  try {
    const headers = {};
    const controller = new AbortController();
    const timeoutId = setTimeout(controller.abort, metadataTimeoutMs);
    const endpoint = proxyEnabled ? addMetadataToEncodedUrl(processedUrl, proxyUrl) : `${processedUrl}/${METADATA_PATH}`;
    if (proxyEnabled) {
      headers[AUTHORIZATION_HEADER] = `${AUTH_TYPE.BEARER} ${token}`;
    }
    const response = await fetch(endpoint, {
      signal: controller.signal,
      headers
    });
    clearTimeout(timeoutId);
    const json = await response.json();
    updateUnitsMetadata(processedUrl, json);
  } catch (e) {
    updateUnitsMetadata(processedUrl, null);
  }
};

export { fetchMetadataFile };
