import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { color } from 'utils/color';
import { Measurement, useAuth, useMeasurementUnits } from '@danfoss/etui-sm';
import { HistoryCfg } from '@danfoss/etui-sm-xml';
import { getHistoryUnitsConfig } from 'utils/get-history-units-config';
import { ChartData, DateRange, LegendMap } from './HistoryPage.types';
import {
  runChecksForQueries,
  startHistoryQueries,
} from './utils/history-actions';
import { processData } from './utils/history-parsing';

export interface HistoryContextProps {
  chartData: ChartData[] | null;
  dateRange: DateRange;
  fetchHistory: (selected: HistoryCfg[]) => void;
  formats: Measurement;
  loadHistoryFromHSTFile: (file: any) => void; // TODO: Add logic to parse HST files
  loading: boolean;
  loadProgress: number;
  parameterMetadata: LegendMap;
  setChartData: (data: ChartData[] | null) => void;
  setDateRange: (dateRange: DateRange) => void;
  setFormats: React.Dispatch<React.SetStateAction<Measurement>>;
  setParameterMetadata: (value: React.SetStateAction<LegendMap>) => void;
}

function getInitDateRange(start: string, end: string) {
  if (start && end) {
    return { startDate: new Date(+start), endDate: new Date(+end) };
  }

  const twelveHoursAgo = new Date(new Date().getHours() - 12);
  return {
    startDate: twelveHoursAgo,
    endDate: new Date(),
  };
}

// Create the history context
export const HistoryContext = createContext<HistoryContextProps>(null);

// Create a custom hook to access the history context
export const useHistoryContext = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error(
      'useHistoryContext must be used within a HistoryContextProvider',
    );
  }
  return context;
};

const START_TIME = 's';
const END_TIME = 'e';

const HistoryContextProvider: React.FC = ({ children }) => {
  const urlDateRange = new URLSearchParams(useLocation().search);
  const { user } = useAuth();

  const [chartData, setChartData] = React.useState<any[]>(null);
  const [dateRange, setDateRange] = React.useState<DateRange>(
    getInitDateRange(urlDateRange.get(START_TIME), urlDateRange.get(END_TIME)),
  );
  const [loadProgress, setLoadProgress] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formats, setFormats] = React.useState<Measurement>(null);
  const measurements = useMeasurementUnits();
  useEffect(() => {
    setFormats(measurements);
  }, [measurements]);

  const fetchHistory = async (selected: HistoryCfg[]) => {
    setChartData(null);
    setLoadProgress(0);

    const { mSecsOffset255toPC } = selected[0]; // TODO different units might have different offsets!
    const { startDate, endDate } = dateRange;

    try {
      setLoading(true);
      const queries = await startHistoryQueries(
        user,
        Math.round((+startDate + mSecsOffset255toPC) / 1e3),
        Math.round((+endDate + mSecsOffset255toPC) / 1e3),
        selected,
      );
      const rawData = await runChecksForQueries(
        user,
        queries,
        setLoadProgress,
        false, // shouldFetchHistoryByResource,
      );

      const processedData = processData(
        rawData,
        selected,
        getHistoryUnitsConfig(measurements),
      );

      const sortedData = processedData.map(dataSet => {
        const sorted = dataSet.data.slice().sort((a, b) => a.time - b.time);
        return { ...dataSet, data: sorted };
      });

      setChartData(sortedData);
    } catch (e) {
      // handleHistoryError(e);
    } finally {
      setLoadProgress(100);
      setLoading(false);
      // setShouldRequestAlarms(true);
    }
  };

  const [parameterMetadata, setParameterMetadata] = React.useState<LegendMap>(
    {},
  );

  React.useEffect(() => {
    if (chartData === null) return;
    const colors = color();

    const metadata = chartData.reduce(
      (acc, { param }) => ({
        ...acc,
        [param.id]: {
          isHidden: false,
          color: colors.next().value,
        },
      }),
      {},
    );

    setParameterMetadata(metadata);
    return () => {};
  }, [chartData]);

  const loadHistoryFromHSTFile = (_file: any) => {
    throw new Error('Not implemented');
  };

  return (
    <HistoryContext.Provider
      value={{
        chartData,
        dateRange,
        fetchHistory,
        formats,
        loadHistoryFromHSTFile,
        loading,
        loadProgress,
        parameterMetadata,
        setChartData,
        setDateRange,
        setFormats,
        setParameterMetadata,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryContextProvider;
