import { useAuth, useUnit, useXmlResource } from '@danfoss/etui-sm';
import { useHistory } from 'react-router';
import { routePaths } from 'routePaths';
import { getMasterUnitMacAddress } from 'utils/get-master-unit-mac-address';
import React from 'react';
import { azureToken, azureAccount } from '../../../config';
import { getStorageHook } from '../utils/get-storage-hook';

const CONTAINER_NAME = 'scada-container';
const AZURE_TOKEN = azureToken;
const AZURE_ACCOUNT = azureAccount;
const useStorage = getStorageHook();
export function useScadaStorage() {
  const [token] = React.useState(AZURE_TOKEN);
  const [account] = React.useState(AZURE_ACCOUNT);
  const { replace } = useHistory();
  if (useStorage === null) {
    // no hook for provided storage
    replace(routePaths.pages.home);
  }
  const {
    units = [],
    loadState: unitsLoadState,
    loadError: unitLoadError,
  } = useUnit();
  const { url } = useXmlResource();
  const { user } = useAuth();
  const masterUnitMacAddress = getMasterUnitMacAddress(units);
  const { storage, error: storageConnectionError } = useStorage(
    masterUnitMacAddress,
    {
      // xml-storage
      user,
      url,
      // azure-storage
      ACCOUNT: account,
      SAS_TOKEN: token,
      CONTAINER_NAME,
    },
  );

  const isLoading =
    (unitsLoadState !== 'done' || !storage) &&
    !storageConnectionError &&
    !unitLoadError;
  return { storage, storageConnectionError, isLoading, unitLoadError };
}
