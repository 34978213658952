import * as React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import {
  Alert,
  Button,
  ColumnProps,
  CommandBar,
  Icon,
  icons,
  IconSize,
  InputAddon,
  Modal,
  Table,
  TextInput,
  Tooltip,
  TooltipPlacement,
  useResponsive,
} from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import {
  EmptyState,
  Protocol,
  getLanguageCodeByName,
  sortStringAlphabetically,
  useApp,
  useAuth,
  useModal,
  useSearch,
} from '@danfoss/etui-sm';
import { Dropdown } from 'components/Dropdown';
import {
  verifyAddressBookPassword,
  getAddressBook,
  getAddressBookPasswordHash,
  getRecentSites,
  mergeAddressBooks,
  reencryptAddressBook,
  removeAddressBookPassword,
  removeSiteFromAddressBook,
  setUserSites,
  validateAdressBookItems,
} from 'utils/addressbook-helpers';
import {
  AddressBook as AddressBookType,
  AddressBookItem,
  NOT_AVAILABLE,
} from 'types';
import { openSiteInANewTab } from 'pages/LoginPage/utils/openNewBrowserTab';
import { getAddressBookPasswordSHA, decrypt } from 'utils/crypto-helpers';
import { usePasswordPrompt } from '../../hooks';
import { showErrorNotification, showSuccessNotification } from '../../utils';
import { isElectron } from '../../../../config';
import { AddressInfoModal } from './AddressInfoModal';
import { ConfirmationModal } from './ConfirmationModal';
import { ExportModal } from './ExportModal';

export const AddressBook = ({
  isOpen,
  onClose,
  switchToLoginForm,
  updateRecentsView,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuth();
  const [state, dispatch] = useApp();
  const langCode = getLanguageCodeByName(user?.language);
  const isMasterPasswordMissing = !getAddressBookPasswordHash();

  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });
  const isMd = screenIsAtMost('md', ['portrait', 'landscape']);

  const [addressBook, setAddressBook] = React.useState<AddressBookItem[]>([]);
  const [addressBookPassword, setAddressBookPassword] = React.useState('');
  const [selectedAddress, setSelectedAddress] =
    React.useState<AddressBookItem | null>(null);

  const getSiteWithDecryptedPassword = React.useCallback(
    (site: AddressBookItem, addressBookPassword: string): AddressBookItem => ({
      ...site,
      password: decrypt(site.password, addressBookPassword),
    }),
    [],
  );

  const {
    setPromptMessage,
    onPromptResolve,
    showPasswordPrompt,
    closePasswordPrompt,
  } = usePasswordPrompt();

  const {
    data: filteredTableData,
    searchValue,
    onSearchInputChange,
    updateInitData,
  } = useSearch(addressBook, ['name', 'ipAddress', 'user']);

  const updateAddressBookView = () => {
    const currentAddressBookState = getAddressBook();
    updateInitData(currentAddressBookState);
    setAddressBook(currentAddressBookState);
  };

  const [showAddressInfo] = useModal(AddressInfoModal, {
    selectedAddress,
    addressBookPassword,
    onInfoModalClose: updateAddressBookView,
  });

  const [openConfiramtionModal, closeConfirmationModal] = useModal(
    ConfirmationModal,
    {
      onConfirm: () => {
        clearAddressBook();
        closeConfirmationModal();
      },
    },
  );

  const [openExportModal] = useModal(ExportModal);

  const withPasswordConfirmation = (
    action: (password?: string) => void,
    successMessage = '',
  ) => {
    const handleAddressBookPwdInput = (password: string) => {
      try {
        verifyAddressBookPassword(password);
        action(password);
        updateAddressBookView();
        successMessage && showSuccessNotification(theme, t, successMessage);
        closePasswordPrompt();
      } catch (e) {
        showErrorNotification(theme, t, e);
      }
    };

    setPromptMessage('t3489');
    onPromptResolve(handleAddressBookPwdInput);
    showPasswordPrompt();
  };

  const loginToSite = (site: AddressBookItem) => {
    withPasswordConfirmation((addressBookPwd: string) => {
      if (state.protocol !== site.protocol.value) {
        if (isElectron) {
          dispatch({
            type: 'SET_PROTOCOL',
            payload:
              state.protocol === Protocol.http ? Protocol.https : Protocol.http,
          });
          switchToLoginForm(getSiteWithDecryptedPassword(site, addressBookPwd));
        } else {
          openSiteInANewTab(site, addressBookPwd);
        }
      } else {
        switchToLoginForm(getSiteWithDecryptedPassword(site, addressBookPwd));
      }
    });
  };

  const addNewAddress = () => {
    withPasswordConfirmation((addressBookPwd: string) => {
      setSelectedAddress({} as AddressBookItem);
      setAddressBookPassword(addressBookPwd);
      showAddressInfo();
    });
  };

  const editAddressBookItem = (address: AddressBookItem) => {
    withPasswordConfirmation((addressBookPwd: string) => {
      setSelectedAddress(address);
      setAddressBookPassword(addressBookPwd);
      showAddressInfo();
    });
  };

  const removeAddressBookItem = (address: AddressBookItem) => {
    withPasswordConfirmation(
      () => removeSiteFromAddressBook(address.id),
      t('t3475'),
    );
  };

  const importAddressBook = () => {
    withPasswordConfirmation((addressBookPwd: string) => {
      setAddressBookPassword(addressBookPwd);
      const fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute('accept', '.json');
      fileInput.addEventListener('change', e =>
        onImportFileChange(e, addressBookPwd),
      );
      fileInput.click();
    });
  };

  const onImportFileChange = async (e: Event, addressBookPassword: string) => {
    const file = (e.target as HTMLInputElement).files[0];
    const fileContent = await file.text();

    try {
      const { version, data, hash }: AddressBookType = JSON.parse(fileContent);

      if (![version, data].every(Boolean) || !validateAdressBookItems(data))
        throw new Error(t('t3477'));

      if (!data.length) throw new Error('t3515');

      const finishImport = (importedAddressBookPwd: string = '') => {
        const updatedSites = reencryptAddressBook(
          data,
          importedAddressBookPwd,
          addressBookPassword,
        );
        mergeAddressBooks(updatedSites);
        updateAddressBookView();
        showSuccessNotification(theme, t, t('t3461'));
      };

      if (hash) {
        const handleAddressBookPwdInput = (importedAddressBookPwd: string) => {
          try {
            const isValid =
              getAddressBookPasswordSHA(importedAddressBookPwd) === hash;
            if (!isValid) throw new Error('t3497');
            finishImport(importedAddressBookPwd);
            closePasswordPrompt();
          } catch (e) {
            showErrorNotification(theme, t, e);
          }
        };

        setPromptMessage('t3494');
        onPromptResolve(handleAddressBookPwdInput);
        showPasswordPrompt();
      } else {
        finishImport();
      }
    } catch (error) {
      showErrorNotification(theme, t, error);
    }
  };

  const createAdressBookPassword = () => {
    withPasswordConfirmation(
      (addressBookPwd: string) => setAddressBookPassword(addressBookPwd),
      t('t3495'),
    );
  };

  const clearAddressBook = () => {
    const recentSites = getRecentSites().map(site => {
      delete site.password;
      site.inAddressBook = false;
      return site;
    });
    setAddressBookPassword('');
    setUserSites(recentSites);
    removeAddressBookPassword();
    updateAddressBookView();
    showSuccessNotification(theme, t, t('t3512'));
  };

  const actions = [
    {
      label: 't2204',
      action: editAddressBookItem,
      testId: 'address-book-btn-edit',
    },
    {
      label: 't2215',
      action: removeAddressBookItem,
      testId: 'address-book-btn-remove',
    },
  ];

  const allColumns: ColumnProps<AddressBookItem>[] = [
    {
      title: t('t76'),
      key: 'name',
      dataIndex: 'name',
      width: '17%',
      ellipsis: true,
      sorter: (a: AddressBookItem, b: AddressBookItem) =>
        sortStringAlphabetically(a.name, b.name, langCode),
      render: (name: string) => name || NOT_AVAILABLE,
    },
    {
      title: t('t3386'),
      key: 'ipAddress',
      dataIndex: 'ipAddress',
      width: '20%',
      ellipsis: true,
      sorter: (a: AddressBookItem, b: AddressBookItem) =>
        sortStringAlphabetically(a.ipAddress, b.ipAddress, langCode),
    },
    {
      title: t('t71'),
      key: 'user',
      dataIndex: 'user',
      width: '15%',
      sorter: (a: AddressBookItem, b: AddressBookItem) =>
        sortStringAlphabetically(a.user, b.user, langCode),
    },
    {
      title: t('t72'),
      key: 'password',
      width: '15%',
      render: () => <Span>********</Span>,
    },
    {
      title: t('t3459'),
      key: 'lastAccessed',
      dataIndex: 'lastAccessed',
      width: '18%',
      render: (date: string) => {
        if (date === NOT_AVAILABLE) return date;

        const newDate = new Date(date);
        return `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()}`;
      },
      sorter: (a: AddressBookItem, b: AddressBookItem) =>
        +new Date(a.lastAccessed === NOT_AVAILABLE ? 0 : a.lastAccessed) -
        +new Date(b.lastAccessed === NOT_AVAILABLE ? 0 : b.lastAccessed),
    },
    {
      title: t('t3478'),
      key: 'control',
      width: '15%',
      render: (site: AddressBookItem) => (
        <Div display="flex">
          <Dropdown
            wrapperStyle={{ zIndex: 1 }}
            closeOnSelect={true}
            button={
              <Button
                testId="address-book-options-btn"
                variant="tertiary"
                iconProps={{ glyph: icons.DOTS_HORIZONTAL, size: 32 }}
                p={0}
              />
            }
          >
            {actions.map(({ label, action, testId }) => (
              <Button
                key={label}
                onClick={() => action(site)}
                variant="tertiary"
                styles={{
                  root: { justifyContent: 'flex-start' },
                }}
                testId={testId}
              >
                {t(label)}
              </Button>
            ))}
          </Dropdown>
          <Tooltip message={t('t3471')} placement="left">
            <Button
              variant="tertiary"
              iconProps={{ glyph: icons.CHEVRON_DOWN, size: 16, rotate: -90 }}
              small={true}
              onClick={() => loginToSite(site)}
            />
          </Tooltip>
        </Div>
      ),
    },
  ];

  const columns = isMd
    ? allColumns.filter(({ key }) =>
        ['name', 'ipAddress', 'control'].includes(`${key}`),
      )
    : allColumns;

  const alertStyles = React.useMemo(
    () => ({
      root: {
        m: `${theme.spacing.xs}px ${theme.spacing.sm}px !important`,
        width: 'auto',
        p: `${theme.spacing.xs}px`,
      },
    }),
    [],
  );

  React.useEffect(() => {
    isOpen && updateAddressBookView();
    return updateRecentsView;
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Header title={t('t3458')} />}
      style={{
        content: {
          maxWidth: isMd ? '360px' : '800px',
          minWidth: isMd ? '360px' : '800px',
        },
      }}
      styles={{
        contentContainer: { p: 0 },
      }}
    >
      {isMasterPasswordMissing ? (
        <Alert type="warning" message={t('t3467')} styles={alertStyles} />
      ) : (
        <Alert
          type="info"
          message={t('t3460')}
          closable={true}
          styles={alertStyles}
        />
      )}
      <CommandBar
        styles={{ root: { mb: `${theme.spacing.xs}px` } }}
        items={[
          {
            key: 'addNewAddress',
            testId: 'addAddressBookItem',
            styles: {
              root: {
                width: 50,
              },
            },
            iconProps: {
              size: 24 as IconSize,
              glyph: icons.ADD,
            },
            onClick: addNewAddress,
            disabled: isMasterPasswordMissing,
          },
          {
            key: 'search',
            testId: 'addressBookSearch',
            onRender() {
              return (
                <TextInput
                  key="searchInput"
                  name="treeFilter"
                  testId="search-input"
                  value={searchValue}
                  onChange={onSearchInputChange}
                  startInputAddon={
                    <InputAddon
                      position="start"
                      isPointerEventsDisabled={true}
                      styles={{
                        root: {
                          top: `14px`,
                          width: '28px',
                          height: '28px',
                        },
                      }}
                    >
                      <Icon glyph={icons.SEARCH} />
                    </InputAddon>
                  }
                  placeholder={t('t3500')}
                  disabled={!addressBook.length}
                  styles={{
                    root: {
                      minWidth: isMd ? '150px' : '300px',
                    },
                    input: {
                      borderStyle: 'none',
                      borderColor: 'none',
                      borderLeft: `1px solid ${theme.palette.black[10]}`,
                    },
                  }}
                />
              );
            },
          },
        ]}
        farItems={[
          {
            key: 'importAddressBook',
            testId: 'importAddressBook',
            iconOnly: true,
            text: t('t3462'),
            tooltipProps: {
              message: t('t3462'),
              placement: 'bottom' as TooltipPlacement,
              styles: {
                root: {
                  mr: '12px',
                },
              },
            },
            iconProps: {
              glyph: icons.IMPORT,
              size: 24 as IconSize,
            },
            onClick: importAddressBook,
            disabled: isMasterPasswordMissing,
          },
          {
            key: 'exportAddressBook',
            testId: 'exportAddressBook',
            iconOnly: true,
            iconProps: {
              glyph: icons.SHARE,
              size: 24 as IconSize,
            },
            text: t('t3463'),
            tooltipProps: {
              message: t('t3463'),
              placement: 'left',
            },
            disabled: !addressBook.length || isMasterPasswordMissing,
            onClick: openExportModal,
          },
        ]}
      />
      <Div height={isMd ? 'auto' : 382} minHeight={150} overflowY="auto">
        <Table
          data-testid="addressbook-table"
          rowKey={({ id }: AddressBookItem) => id}
          columns={columns}
          dataSource={filteredTableData}
          pagination={false}
          emptyPlaceholder={<EmptyState title={t('t3402')} />}
        />
      </Div>
      <Div
        textAlign="end"
        m={
          isMd
            ? `${theme.spacing.xs}px 0`
            : `${theme.spacing.md}px ${theme.spacing.lg}px ${theme.spacing.sm}px`
        }
      >
        {isMasterPasswordMissing ? (
          <Button
            testId="create-address-book-pwd"
            variant="primary"
            mr={theme.spacing.md}
            onClick={createAdressBookPassword}
          >
            {t('t3491')}
          </Button>
        ) : (
          <Button
            testId="clear-address-book"
            variant="secondary"
            mr={theme.spacing.md}
            onClick={openConfiramtionModal}
          >
            {t('t3499')}
          </Button>
        )}
        <Button
          testId="close-address-book"
          variant="tertiary"
          onClick={onClose}
        >
          {t('t108')}
        </Button>
      </Div>
    </Modal>
  );
};
