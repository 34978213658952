import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { Div, P } from '@danfoss/etui-system-elements';
import {
  TextInput,
  Tooltip,
  ellipsis,
  icons,
  Button,
} from '@danfoss/etui-core';

export interface DeviceInfoColumnProps {
  deviceProductValue?: boolean;
  name: string;
  indent?: string;
  host: string;
  addr: string;
  productCode?: string;
  link?: () => void;
  size?: 'default' | 'small';
}

export function DeviceInfoColumn({
  name,
  host,
  addr,
  productCode,
  link,
  indent = '0',
}: DeviceInfoColumnProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const displayName = name || 'Unknown';
  return (
    <Div display="flex" flexDirection="row">
      <Div
        justifyContent="center"
        display="flex"
        flexDirection="column"
        {...theme.typography.button}
        fontSize={theme.typography.button.fontSize}
        whiteSpace="pre"
        lineHeight="inherit"
        overflow="hidden"
        width="100%"
        pl={
          parseInt(indent, 10) !== 0
            ? `${parseInt(indent, 10) * theme.spacing.xs}px`
            : 0
        }
      >
        <Div
          {...(ellipsis() as any)}
          display="inline-flex"
          flexDirection="column"
        >
          <Div
            fontSize="12px"
            fontWeight="normal"
            lineHeight={1.5}
            title={displayName}
            {...(ellipsis() as any)}
          >
            {displayName}
          </Div>
          <P
            {...theme.typography.label}
            fontSize={theme.typography.pxToRem(12)}
            color={theme.palette.black}
            fontWeight="600"
            lineHeight={1.25}
            {...(ellipsis() as any)}
          >
            {addr
              ? `${t('t136')}: ${host} - ${t('t57')}: ${addr || ''}`
              : `${t('t136')}: ${host}`}
          </P>
        </Div>
      </Div>
      {productCode && (
        <Div style={{ display: 'flex', textAlign: 'right' }}>
          <Div style={{ marginLeft: '-50px' }}>
            <TextInput
              textAlign="right"
              fontSize="14px"
              name="productCode"
              label={t('t3510')}
              readOnly={true}
              defaultValue={productCode}
              {...(ellipsis() as any)}
            />
          </Div>
          <Div style={{ marginLeft: '50px', position: 'absolute' }}>
            <Tooltip message={t('t3511')} placement="bottom">
              <Button
                onClick={link}
                variant="tertiary"
                iconProps={{
                  glyph: icons.EXTERNAL,
                  size: 16,
                }}
              />
            </Tooltip>
          </Div>
        </Div>
      )}
    </Div>
  );
}
