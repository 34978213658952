import '../constants/session-storage-constants.js';
import '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import '../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import csv from 'csvtojson';
import './fetch-xml.js';
import './fetch-xml-file.js';
import './fetch-xml-file-name.js';
import { fetchXMLFileData } from './fetch-xml-file-data.js';

async function fetchXMLCsvFileData(_ref) {
  let {
    url,
    action,
    user,
    password,
    lang,
    offset = 0,
    backup,
    swCmd,
    attributes
  } = _ref;
  const {
    fileContent
  } = await fetchXMLFileData({
    url,
    action,
    user,
    password,
    lang,
    offset,
    backup,
    swCmd,
    attributes
  });
  if (fileContent) {
    return csvFileToJson(fileContent);
  }
  return Promise.reject(new Error('Uncaught error'));
}
async function csvFileToJson(csvFileContent) {
  const jsonData = await csv({
    trim: true
  }).fromString(csvFileContent).preFileLine((fileLine, index) => {
    if (index === 0) {
      return fileLine.toLowerCase().replace(/\s+/g, '');
    }
    return fileLine;
  });
  return jsonData;
}

export { csvFileToJson, fetchXMLCsvFileData as fetchXmlCsvFileData };
