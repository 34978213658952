import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import '../../constants/xml-action-constant.js';
import '../../constants/xml-device-constant.js';
import '../../constants/xml-tag-constant.js';
import { getDeviceActionByDeviceType } from '../../helpers/device-helpers.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import '../../types/Unit.js';
import { _ as _objectSpread2, a as _objectWithoutProperties } from '../../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/url-helpers/get-is-valid-dns.js';
import '../../helpers/url-helpers/get-is-valid-ip.js';
import '../../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../../helpers/url-helpers/get-is-valid-port.js';
import '../../helpers/url-helpers/get-url-info.js';
import '../../helpers/unit-helpers/initial-unit-utils.js';
import '../../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import { getUnitUrl } from '../../helpers/unit-helpers/get-unit-url.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../../fetch-xml/fetch-xml-data.js';

const _excluded = ["ambient", "device"];
async function fetchHvacDevicesByUnit(sourceUrl, unit, deviceType, user, skipSessionUpdate) {
  const action = getDeviceActionByDeviceType(deviceType);
  const _await$fetchXMLData = await fetchXMLData({
      url: getUnitUrl(sourceUrl, unit),
      attributes: _objectSpread2({
        user: user.user,
        password: user.password,
        lang: user.language,
        storeview_only: '1',
        version: 'C',
        action
      }, skipSessionUpdate ? {
        session_update: 'no'
      } : {})
    }),
    {
      ambient,
      device
    } = _await$fetchXMLData,
    response = _objectWithoutProperties(_await$fetchXMLData, _excluded);
  return _objectSpread2(_objectSpread2({}, response), {}, {
    device: device ? Array.isArray(device) ? device.map(hvacDevice => _objectSpread2(_objectSpread2({}, hvacDevice), {}, {
      ambient
    })) : [_objectSpread2(_objectSpread2({}, device), {}, {
      ambient
    })] : []
  });
}

export { fetchHvacDevicesByUnit };
