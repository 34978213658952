import {
  SelectedValueType,
  TreeItem,
  TreeTableItem,
  icons,
} from '@danfoss/etui-core';
import { DefaultTheme } from 'styled-components';
import { DEVICE_TYPE, DiscoveryTableData } from '../types/DiscoveryPage.types';
import {
  AvailableCasesTableData,
  SUCTION_GROUP_DEVICE_LEVELS,
} from '../types/RefrigLayout.types';
import { WizardsTreeTableItem } from '../types';

export const isSuctionMatchesWithPack = (
  suctionDevice: DiscoveryTableData,
  packDevice: DiscoveryTableData,
) =>
  suctionDevice.rk === packDevice.rk &&
  (suctionDevice.deviceType === DEVICE_TYPE.NO_COMP_SUCT ||
    suctionDevice.address === packDevice.address) &&
  suctionDevice.assigned === false;

export const isCaseMatchesWithSuction = (
  caseDevice: DiscoveryTableData,
  suctionDevice: DiscoveryTableData,
) => caseDevice.rk === suctionDevice.rk && caseDevice.sg === suctionDevice.sg;

export const isMultiCaseDevice = (
  currentCaseDevice: DiscoveryTableData,
  caseDevice: DiscoveryTableData,
) =>
  caseDevice.rk === currentCaseDevice.rk &&
  caseDevice.sg === currentCaseDevice.sg &&
  caseDevice.dim2 === currentCaseDevice.dim2 &&
  Number(caseDevice.dim3) >= 2 &&
  caseDevice.assigned === false;

export const getCaseDevicesForCase = (
  currentCaseDevice: DiscoveryTableData,
  caseDevices: DiscoveryTableData[],
) => {
  const matchedMultiCase = caseDevices.filter(
    caseDevice =>
      caseDevice.rk === currentCaseDevice.rk &&
      caseDevice.sg === currentCaseDevice.sg &&
      caseDevice.dim2 === currentCaseDevice.dim2 &&
      Number(caseDevice.dim3) >= 2 &&
      caseDevice.assigned === false,
  );

  return matchedMultiCase;
};

export const getSuctionDevicesForPack = (
  packDevice: DiscoveryTableData,
  suctionDevices: DiscoveryTableData[],
) => {
  const matchedSuctionDevices = suctionDevices.filter(
    suction =>
      suction.rk === packDevice.rk &&
      (suction.deviceType === DEVICE_TYPE.NO_COMP_SUCT ||
        suction.address === packDevice.address),
  );
  return matchedSuctionDevices;
};

export const getCasesForPack = (
  suctionDevice: DiscoveryTableData,
  caseDevices: DiscoveryTableData[],
) => {
  const matchedCaseDevices = caseDevices.filter(
    caseDevice =>
      caseDevice.rk === suctionDevice.rk && caseDevice.sg === suctionDevice.sg,
  );

  return matchedCaseDevices;
};

export const getNoOfCasesAssigned = (childDevices: TreeTableItem[]) => {
  let noOfCases = 0;
  childDevices.forEach(suction => {
    // suction level cases
    noOfCases += suction.items.length;
    if (suction?.items?.length) {
      suction.items.forEach(caseDevice => {
        // multi-case level cases
        if (caseDevice?.items?.length) {
          noOfCases += caseDevice.items.length;
        }
      });
    }
  });
  return noOfCases;
};

export const getRefrigTreeIcons = (icons, value: string) => {
  return Object.keys(icons).find(key => icons[key] === value);
};

export const getSectionsUpdatedForCase = (
  currentSelectedCase: AvailableCasesTableData,
  treeViewData: TreeTableItem[],
) => {
  const sectionsOpenUpdated: number[] = [];

  treeViewData.every(packDevice => {
    if (packDevice?.items?.length) {
      let selectedCaseDevice: TreeItem = null;
      packDevice?.items?.every(suctionDevice => {
        if (suctionDevice?.items?.length) {
          selectedCaseDevice = findCaseInsideSuction(
            suctionDevice,
            currentSelectedCase?.deviceId,
          );
          if (selectedCaseDevice) {
            sectionsOpenUpdated.push(packDevice.id);
            sectionsOpenUpdated.push(suctionDevice.id);
            if (selectedCaseDevice?.items?.length) {
              const multiCaseDevice = selectedCaseDevice.items.find(
                multiCase => multiCase.id === currentSelectedCase.deviceId,
              );
              if (multiCaseDevice)
                sectionsOpenUpdated.push(selectedCaseDevice.id);
            }
            return false;
          }
          return true;
        }
        return true;
      });
      if (selectedCaseDevice) {
        return false;
      }
    }
    return true;
  });

  return sectionsOpenUpdated;
};

export const isFoundInsidePack = (
  packDevice: TreeTableItem,
  selectedDeviceId: number,
) =>
  packDevice?.items?.find(
    suctionDevice => suctionDevice.id === selectedDeviceId,
  ) !== undefined;

export const getMatchedSuction = (
  packDevice: TreeTableItem,
  selectedDeviceId: number,
) =>
  packDevice?.items?.find(
    suctionDevice => suctionDevice.id === selectedDeviceId,
  );

export const isDeviceAtPackLevel = (
  treeViewData: TreeTableItem[],
  selectedDeviceId: number,
) =>
  treeViewData?.find(compressor => compressor?.id === selectedDeviceId) !==
  undefined;

export const isDeviceAtSuctionLevel = (
  treeViewData: TreeTableItem[],
  selectedDeviceId: number,
) => {
  let isSuctionLevel = false;
  treeViewData?.every(packDevice => {
    if (isFoundInsidePack(packDevice, selectedDeviceId)) {
      isSuctionLevel = true;
      return false;
    }

    return true;
  });

  return isSuctionLevel;
};

export const isSuctionDevice = (
  deviceId: number,
  discoveredDevices: DiscoveryTableData[],
) => {
  const device = discoveredDevices.find(device => device.deviceId === deviceId);
  return (
    device.deviceType === DEVICE_TYPE.GEN_SUCT ||
    device.deviceType === DEVICE_TYPE.RACKSUCT
  );
};

export const checkIsParentPack = (
  previousDeviceId: number,
  currentDeviceId: number,
  sectionsOpenUpdated: SelectedValueType[],
  treeViewData: TreeTableItem[],
) => {
  let matchedPackDevice: TreeItem = null;
  treeViewData.every(packDevice => {
    if (isFoundInsidePack(packDevice, currentDeviceId)) {
      matchedPackDevice = packDevice;
      return false;
    }

    return true;
  });

  return (
    matchedPackDevice.id === previousDeviceId ||
    sectionsOpenUpdated.includes(matchedPackDevice.id)
  );
};

export const getFromAndToPackGroup = (
  currentSelectedCase: AvailableCasesTableData,
  compressorControlData: TreeTableItem[],
  toDeviceId: number,
  discoveryTableData: DiscoveryTableData[],
) => {
  let fromPackGroup: string = null;
  let fromSuctionGroup: string = null;
  let fromCaseGroup: string = null;
  let toPackGroup: string = null;
  let toSuctionGroup: string = null;
  let toCaseGroup: string = null;

  if (currentSelectedCase?.assigned) {
    const {
      packGroup: foundFromPackGroup,
      suctionGroup: foundFromSuctionGroup,
      caseGroup: foundFromCaseGroup,
    } = getPackSuctionCaseGroupForDevice(
      compressorControlData,
      currentSelectedCase.deviceId,
      discoveryTableData,
    );

    const {
      packGroup: foundToPackGroup,
      suctionGroup: foundToSuctionGroup,
      caseGroup: foundToCaseGroup,
    } = getPackSuctionCaseGroupForDevice(
      compressorControlData,
      toDeviceId,
      discoveryTableData,
      true,
    );
    fromPackGroup = foundFromPackGroup;
    fromSuctionGroup = foundFromSuctionGroup;
    fromCaseGroup = foundFromCaseGroup;
    toPackGroup = foundToPackGroup;
    toSuctionGroup = foundToSuctionGroup;
    toCaseGroup = foundToCaseGroup;
  } else {
    fromPackGroup = currentSelectedCase.name;
    const {
      packGroup: foundToPackGroup,
      suctionGroup: foundToSuctionGroup,
      caseGroup: foundToCaseGroup,
    } = getPackSuctionCaseGroupForDevice(
      compressorControlData,
      toDeviceId,
      discoveryTableData,
    );
    toPackGroup = foundToPackGroup;
    toSuctionGroup = foundToSuctionGroup;
    toCaseGroup = foundToCaseGroup;
  }

  return {
    fromPackGroup,
    fromSuctionGroup,
    fromCaseGroup,
    toPackGroup,
    toSuctionGroup,
    toCaseGroup,
  };
};

export const getPackSuctionCaseGroupForDevice = (
  compressorControlData: TreeTableItem[],
  deviceId: number,
  discoveryTableData: DiscoveryTableData[],
  isAssignToDevice = false,
) => {
  let packGroup: string = null;
  let suctionGroup: string = null;
  let caseGroup: string = null;
  let caseDevice = null;
  let suctionDevice = null;
  const level = getSelectedDeviceLevel(deviceId, compressorControlData);
  compressorControlData?.every(packDevice => {
    if (packDevice?.items?.length) {
      packDevice?.items?.every(suction => {
        if (suction?.items?.length) {
          caseDevice = suction?.items?.find(cases => cases.id === deviceId);
          if (caseDevice || suction.id === deviceId) {
            packGroup = findDeviceByDeviceId(
              packDevice.id,
              discoveryTableData,
            ).name;
            suctionGroup = findDeviceByDeviceId(
              suction.id,
              discoveryTableData,
            ).name;
            if (isAssignToDevice && level === SUCTION_GROUP_DEVICE_LEVELS.CASE)
              caseGroup = findDeviceByDeviceId(
                caseDevice.id,
                discoveryTableData,
              ).name;
            return false;
          }
          suction?.items?.every(cases => {
            if (cases?.items?.length) {
              caseDevice = cases?.items?.find(
                multiCase => multiCase.id === deviceId,
              );
              if (caseDevice) {
                packGroup = findDeviceByDeviceId(
                  packDevice.id,
                  discoveryTableData,
                ).name;
                suctionGroup = findDeviceByDeviceId(
                  suction.id,
                  discoveryTableData,
                ).name;
                caseGroup = findDeviceByDeviceId(
                  cases.id,
                  discoveryTableData,
                ).name;
                return false;
              }
            }
            return true;
          });
        } else {
          suctionDevice = packDevice?.items?.find(
            suction => suction.id === deviceId,
          );
          if (suctionDevice) {
            packGroup = findDeviceByDeviceId(
              packDevice.id,
              discoveryTableData,
            ).name;
            suctionGroup = findDeviceByDeviceId(
              suction.id,
              discoveryTableData,
            ).name;
            return false;
          }
        }
        return true;
      });
    }
    return true;
  });

  return {
    packGroup,
    suctionGroup,
    caseGroup,
  };
};

export const checkIsSuctionSibling = (
  previousDeviceId: number,
  currentDeviceId: number,
  treeViewData: TreeTableItem[],
) => {
  let matchedPackDevice: TreeItem = null;
  treeViewData.every(packDevice => {
    if (isFoundInsidePack(packDevice, currentDeviceId)) {
      matchedPackDevice = packDevice;
      return false;
    }

    return true;
  });

  return (
    matchedPackDevice.items.find(
      suctionDevice => suctionDevice.id === previousDeviceId,
    ) !== undefined
  );
};

export const checkIsValidOperation = (
  sectionsOpenUpdated: SelectedValueType[],
  discoveredDevices: DiscoveryTableData[],
  treeViewData: TreeTableItem[],
) => {
  const currentDeviceId = sectionsOpenUpdated.at(-1);
  const previousDeviceId = sectionsOpenUpdated.at(-2);
  if (isSuctionDevice(currentDeviceId, discoveredDevices)) {
    if (
      checkIsParentPack(
        previousDeviceId,
        currentDeviceId,
        sectionsOpenUpdated,
        treeViewData,
      )
    ) {
      return true;
    }
    if (
      checkIsSuctionSibling(previousDeviceId, currentDeviceId, treeViewData)
    ) {
      return true;
    }
    return false;
  }
  return true;
};

export const isDeviceAtMultiCaseLevel = (
  selectedDeviceId: number,
  treeViewData: TreeTableItem[],
) => {
  let caseDevice: TreeItem = null;
  treeViewData?.every(packDevice => {
    if (packDevice?.items?.length) {
      packDevice?.items?.every(suctionDevice => {
        if (suctionDevice?.items?.length) {
          caseDevice = findCaseInsideCase(suctionDevice, selectedDeviceId);
          if (caseDevice) {
            return false;
          }
          return true;
        }
        if (caseDevice) return false;
        return true;
      });
    }
    if (caseDevice) return false;
    return true;
  });

  return caseDevice;
};

export const checkIsMultiCaseOperation = (level: SUCTION_GROUP_DEVICE_LEVELS) =>
  level === SUCTION_GROUP_DEVICE_LEVELS.CASE ||
  level === SUCTION_GROUP_DEVICE_LEVELS.MULTI_CASE;

export const getSelectedDeviceLevel = (
  selectedDeviceId: number,
  treeViewData: TreeTableItem[],
): SUCTION_GROUP_DEVICE_LEVELS => {
  let level = null;

  if (isDeviceAtPackLevel(treeViewData, selectedDeviceId)) {
    level = SUCTION_GROUP_DEVICE_LEVELS.PACK_RACK_NOCOMP;
    return level;
  }
  if (isDeviceAtSuctionLevel(treeViewData, selectedDeviceId)) {
    level = SUCTION_GROUP_DEVICE_LEVELS.SUCTION;
    return level;
  }
  if (isDeviceAtMultiCaseLevel(selectedDeviceId, treeViewData)) {
    level = SUCTION_GROUP_DEVICE_LEVELS.MULTI_CASE;
    return level;
  }
  level = SUCTION_GROUP_DEVICE_LEVELS.CASE;
  return level;
};

export const isOrphanedCase = (
  selectedDeviceId: number,
  treeViewData: TreeTableItem[],
) => {
  const caseObj = findCaseInTree(treeViewData, selectedDeviceId);
  return caseObj?.items?.length === 0;
};

export const filterOutOrphanedCaseIds = (
  sectionsOpenUpdated: SelectedValueType[],
  compressorControlData: TreeTableItem[],
) => {
  const updatedSectionsOpenUpdated = [];
  sectionsOpenUpdated.forEach(sectionId => {
    const deviceLevel = getSelectedDeviceLevel(
      sectionId,
      compressorControlData,
    );
    if (
      deviceLevel === SUCTION_GROUP_DEVICE_LEVELS.CASE &&
      isOrphanedCase(sectionId, compressorControlData)
    ) {
      updatedSectionsOpenUpdated.push(sectionId);
    }
  });
  if (updatedSectionsOpenUpdated.length) {
    updatedSectionsOpenUpdated.forEach(sectionId => {
      sectionsOpenUpdated.splice(sectionsOpenUpdated.indexOf(sectionId), 1);
    });
  }
  return sectionsOpenUpdated;
};

export const findSuctionInTree = (
  treeViewData: TreeTableItem[],
  suctionId: number,
) => {
  let suctionDevice: TreeItem = null;
  treeViewData.every(packDevice => {
    if (isFoundInsidePack(packDevice, suctionId)) {
      suctionDevice = getMatchedSuction(packDevice, suctionId);
      return false;
    }

    return true;
  });

  return suctionDevice;
};

export const findCaseInsideCase = (suctionDevice: TreeItem, caseId: number) => {
  let caseDevice: TreeItem = null;
  suctionDevice?.items?.every(cases => {
    if (cases?.items?.length) {
      caseDevice =
        cases?.items?.find(caseDevice => caseDevice.id === caseId) !== undefined
          ? cases
          : null;
    }
    if (caseDevice) return false;
    return true;
  });

  return caseDevice;
};

export const findCaseInsideSuction = (
  suctionDevice: TreeItem,
  caseId: number,
) => {
  let caseDevice: TreeItem = null;
  caseDevice = suctionDevice?.items?.find(
    caseDevice => caseDevice.id === caseId,
  );
  if (!caseDevice) {
    suctionDevice?.items?.every(cases => {
      if (cases?.items?.length) {
        caseDevice =
          cases?.items?.find(caseDevice => caseDevice.id === caseId) !==
          undefined
            ? cases
            : null;
      }
      if (caseDevice) return false;
      return true;
    });
  }
  return caseDevice;
};

export const findCase = (caseId: number, packDevice: TreeItem) => {
  let caseDevice: TreeItem = null;
  packDevice?.items?.every(suctionDevice => {
    if (suctionDevice?.items?.length) {
      caseDevice = findCaseInsideSuction(suctionDevice, caseId);
      if (caseDevice) {
        return false;
      }
      return true;
    }
    return true;
  });

  return caseDevice;
};

export const findCaseGroup = (caseId: number, packDevice: TreeItem) => {
  let caseDevice: TreeItem = null;
  let caseGroup: TreeItem = null;
  packDevice?.items?.every(suctionDevice => {
    if (suctionDevice?.items?.length) {
      caseDevice = findCaseInsideSuction(suctionDevice, caseId);
      if (caseDevice) {
        if (caseDevice.glyph === 'CASE_EVAPORATOR' && caseDevice.items.length) {
          caseGroup = caseDevice;
        } else {
          caseGroup = suctionDevice;
        }
        return false;
      }
      return true;
    }
    return true;
  });

  return caseGroup;
};

export const findSiblingCasesById = (
  treeViewData: TreeTableItem[],
  caseId: number,
) => {
  let caseGroup: TreeItem = null;
  treeViewData.every(packDevice => {
    if (packDevice?.items?.length) {
      caseGroup = findCaseGroup(caseId, packDevice);
      if (caseGroup) {
        return false;
      }
      return true;
    }

    return true;
  });

  return caseGroup;
};

export const findCaseInTree = (
  treeViewData: TreeTableItem[],
  caseId: number,
) => {
  let caseDevice: TreeItem = null;
  treeViewData.every(packDevice => {
    if (packDevice?.items?.length) {
      caseDevice = findCase(caseId, packDevice);
      if (caseDevice) {
        return false;
      }
      return true;
    }

    return true;
  });

  return caseDevice;
};

export const getSelectedDeviceCasesIds = (
  deviceLevel: string,
  compressorControlData: TreeTableItem[],
  currentSelectedDeviceId: number,
) => {
  let updatedSelectedCaseDeviceIds: SelectedValueType[] = [];
  switch (deviceLevel) {
    case SUCTION_GROUP_DEVICE_LEVELS.PACK_RACK_NOCOMP: {
      const selectedCompressor = compressorControlData?.find(
        compressor => compressor.id === currentSelectedDeviceId,
      );
      selectedCompressor?.items?.forEach(suction => {
        suction?.items?.forEach(cases => {
          updatedSelectedCaseDeviceIds.push(cases?.id);
          if (cases.items.length) {
            cases?.items?.forEach(multiCase => {
              updatedSelectedCaseDeviceIds.push(multiCase.id);
            });
          }
        });
      });
      break;
    }
    case SUCTION_GROUP_DEVICE_LEVELS.SUCTION: {
      const selectedSuction = findSuctionInTree(
        compressorControlData,
        currentSelectedDeviceId,
      );
      selectedSuction?.items?.forEach(cases => {
        updatedSelectedCaseDeviceIds.push(cases?.id);
        if (cases.items.length) {
          cases?.items?.forEach(multiCase => {
            updatedSelectedCaseDeviceIds.push(multiCase.id);
          });
        }
      });
      break;
    }
    case SUCTION_GROUP_DEVICE_LEVELS.CASE: {
      const selectedCase = findCaseInTree(
        compressorControlData,
        currentSelectedDeviceId,
      );
      if (selectedCase?.items?.length) {
        updatedSelectedCaseDeviceIds.push(currentSelectedDeviceId);
        selectedCase?.items?.forEach(cases => {
          updatedSelectedCaseDeviceIds.push(cases.id);
        });
      }

      break;
    }
    default:
      updatedSelectedCaseDeviceIds = [];
  }

  return updatedSelectedCaseDeviceIds;
};

export const getRecordColor = (
  record: AvailableCasesTableData,
  selectedRowKeys: number[],
  theme: DefaultTheme,
  selectedCompressorTreeDeviceId: number,
) =>
  record.assigned &&
  (selectedCompressorTreeDeviceId === undefined
    ? selectedRowKeys.length !== 0
    : true) &&
  !selectedRowKeys.includes(record.key) &&
  theme.palette.grey[40];

export const isCaseAssignAllowed = (
  discoveryTableData: DiscoveryTableData[],
  currenSectionOpen: number[],
  compressorControlData: TreeTableItem[],
  currentCaseDeviceId: number,
) => {
  let isAllowed = true;
  const caseList = findSiblingCasesById(
    compressorControlData,
    currentCaseDeviceId,
  );
  const currenDeviceSelectedToAssign = findDeviceByDeviceId(
    currenSectionOpen.at(-1),
    discoveryTableData,
  );
  const selectedCaseDeviceToAssign = findDeviceByDeviceId(
    currentCaseDeviceId,
    discoveryTableData,
  );
  caseList?.items?.every(cases => {
    const caseDevice = findDeviceByDeviceId(cases.id, discoveryTableData);
    if (checkIsAkIoAndConfigured(caseDevice)) {
      isAllowed = false;
      return false;
    }
    if (checkIsAkIoAndConfigured(currenDeviceSelectedToAssign)) {
      isAllowed = false;
      return false;
    }
    const targetSuction = findSuctionInTree(
      compressorControlData,
      currentCaseDeviceId,
    );
    if (
      targetSuction?.items?.length &&
      (caseDevice.deviceType === DEVICE_TYPE.CIRCUIT ||
        caseDevice.deviceType === DEVICE_TYPE.GEN_CIRCUIT)
    ) {
      targetSuction?.items?.every(cases => {
        if (checkIsAkIoAndConfigured(cases)) {
          isAllowed = false;
          return false;
        }
        return true;
      });
    }

    return true;
  });
  if (!isAllowed) {
    if (selectedCaseDeviceToAssign?.cfg === '1') {
      return false;
    }
    if (
      currenDeviceSelectedToAssign?.cfg === '1' &&
      currenDeviceSelectedToAssign?.deviceType !== DEVICE_TYPE.GEN_SUCT &&
      currenDeviceSelectedToAssign?.deviceType !== DEVICE_TYPE.NO_COMP_SUCT &&
      currenDeviceSelectedToAssign?.deviceType !== DEVICE_TYPE.RACKSUCT
    ) {
      return false;
    }
  }
  return true;
};

export const isCaseAssign = (currentSelectedCase: AvailableCasesTableData) =>
  currentSelectedCase.checked === false;

export const checkIsCaseAssignOperation = (
  selectedKeys,
  searchedAvailableCasesData: AvailableCasesTableData[],
) =>
  searchedAvailableCasesData.find(
    caseData => caseData.id === selectedKeys.at(-1),
  ).checked === false;

export const findSelectedCaseToAssign = (
  selectedKeys: number[],
  searchedAvailableCasesData: AvailableCasesTableData[],
) => {
  const assignCase = searchedAvailableCasesData.find(
    caseData => caseData.id === selectedKeys.at(-1),
  );
  return assignCase;
};

export const findSelectedCaseToRemove = (
  selectedKeys: number[],
  currentSelectedRowKeys: number[],
  searchedAvailableCasesData: AvailableCasesTableData[],
) => {
  let removingCaseId = null;
  currentSelectedRowKeys.every(currenSelectedRowKey => {
    if (!selectedKeys.includes(currenSelectedRowKey)) {
      removingCaseId = currenSelectedRowKey;
      return false;
    }
    return true;
  });
  return searchedAvailableCasesData?.find(
    searchedCases => searchedCases.id === removingCaseId,
  );
};

export const isCaseRemove = (
  currentSelectedCaseDeviceIds: number[],
  record: AvailableCasesTableData[],
) => currentSelectedCaseDeviceIds.length - record.length === 1;

export const removeCaseFromTree = (
  compressorDataTreeToRemove: WizardsTreeTableItem[],
  caseToRemove: AvailableCasesTableData,
  discoveryTableData?: DiscoveryTableData[],
) => {
  let caseTreeObj: WizardsTreeTableItem = null;
  const level = getSelectedDeviceLevel(
    caseToRemove.deviceId,
    compressorDataTreeToRemove,
  );
  if (!caseToRemove.assigned) {
    caseTreeObj = {
      id: caseToRemove.deviceId,
      name: caseToRemove.name,
      address: caseToRemove.address,
      model: caseToRemove.model,
      icon: icons.CASE_EVAPORATOR,
      items: [],
      allowRowSelection: true,
    };
  } else if (
    level === SUCTION_GROUP_DEVICE_LEVELS.MULTI_CASE ||
    level === SUCTION_GROUP_DEVICE_LEVELS.CASE
  ) {
    compressorDataTreeToRemove?.every(compressor => {
      if (compressor?.items?.length) {
        compressor?.items?.every(suction => {
          if (suction?.items?.length) {
            suction?.items?.every((cases, index) => {
              if (cases.id === caseToRemove?.deviceId) {
                // if removing case is a multi-case parent
                caseTreeObj = cases;
                cases?.items?.forEach(multiCaseItems => {
                  suction?.items?.push(multiCaseItems);
                });
                suction?.items?.splice(index, 1);
                caseTreeObj.items.length = 0;
                return false;
              }
              if (cases?.items?.length) {
                // else remvoing case is inside the multi case parent
                cases?.items?.every((multiCases, index) => {
                  if (multiCases?.id === caseToRemove?.deviceId) {
                    caseTreeObj = multiCases;
                    cases?.items?.splice(index, 1);
                    return false;
                  }
                  return true;
                });
                if (caseTreeObj) return false;
              }

              return true;
            });
            if (caseTreeObj) {
              suction?.items?.forEach((cases, index) => {
                const caseDevice = discoveryTableData?.find(
                  device => device?.deviceId === cases?.id,
                );
                const newDim2 = ++index;
                caseDevice.dim2 = newDim2.toString();
                caseDevice.dim3 = '1';
                if (cases?.items?.length) {
                  let parentDim3 = +discoveryTableData?.find(
                    device => device?.deviceId === cases.id,
                  ).dim3;
                  cases?.items?.forEach(multiCase => {
                    const multiCaseDevice = discoveryTableData?.find(
                      device => device?.deviceId === multiCase.id,
                    );
                    const newDim3 = ++parentDim3;
                    multiCaseDevice.dim3 = newDim3.toString();
                  });
                }
              });
              return false;
            }
          }
          return true;
        });
        if (caseTreeObj) return false;
      }
      return true;
    });
  }
  return {
    caseRemovedCompressorTree: compressorDataTreeToRemove,
    removedCaseTreeObj: caseTreeObj,
    dimValuesUpdatedDiscoveryTableData: discoveryTableData,
  };
};

export const unAssignDevice = (
  discoveryTableData: DiscoveryTableData[],
  currentSelectedCaseDeviceIds: number,
) => {
  const caseDeviceToUnAssign = discoveryTableData?.find(
    cases => cases?.deviceId === currentSelectedCaseDeviceIds,
  );
  caseDeviceToUnAssign.assigned = false;
  caseDeviceToUnAssign.rk = 255;
  caseDeviceToUnAssign.sg = 255;
  caseDeviceToUnAssign.dim1 = '0';
  caseDeviceToUnAssign.dim2 = '0';
  caseDeviceToUnAssign.dim3 = '0';
  return discoveryTableData;
};

export const assignCaseToSelectedSuction = (
  compressorDataTreeToAssignCase: WizardsTreeTableItem[],
  caseTreeObj: WizardsTreeTableItem,
  currentSelectedDeviceIdToAssign: number,
  discoveryTableData: DiscoveryTableData[],
) => {
  const levelToAssign = getSelectedDeviceLevel(
    currentSelectedDeviceIdToAssign,
    compressorDataTreeToAssignCase,
  );
  if (levelToAssign === SUCTION_GROUP_DEVICE_LEVELS.SUCTION) {
    compressorDataTreeToAssignCase?.every(compressor => {
      if (compressor?.items?.length) {
        compressor?.items?.every(suction => {
          if (suction?.id === currentSelectedDeviceIdToAssign) {
            const caseDevice = discoveryTableData?.find(
              device => device?.deviceId === caseTreeObj?.id,
            );
            const suctionDevice = discoveryTableData?.find(
              device => device?.deviceId === suction?.id,
            );
            caseDevice.rk = suctionDevice?.rk;
            caseDevice.sg = suctionDevice?.sg;
            caseDevice.dim1 = suctionDevice?.rk.toString();
            const newDim2 = suction?.items?.length + 1;
            caseDevice.dim2 = newDim2.toString();
            caseDevice.dim3 = '1';
            caseDevice.assigned = true;
            suction?.items?.push(caseTreeObj);
            caseTreeObj = null;
            return false;
          }
          return true;
        });
        if (!caseTreeObj) return false;
      }
      return true;
    });
  }
  if (
    levelToAssign === SUCTION_GROUP_DEVICE_LEVELS.CASE ||
    levelToAssign === SUCTION_GROUP_DEVICE_LEVELS.MULTI_CASE
  ) {
    compressorDataTreeToAssignCase?.every(compressor => {
      if (compressor?.items?.length) {
        compressor?.items?.every(suction => {
          if (suction?.items?.length) {
            suction?.items?.every(cases => {
              if (cases?.id === currentSelectedDeviceIdToAssign) {
                const multiCaseChildDevice = discoveryTableData?.find(
                  device => device?.deviceId === caseTreeObj?.id,
                );
                const multiCaseDeviceParent = discoveryTableData?.find(
                  device => device?.deviceId === cases?.id,
                );
                multiCaseChildDevice.rk = multiCaseDeviceParent?.rk;
                multiCaseChildDevice.sg = multiCaseDeviceParent?.sg;
                multiCaseChildDevice.dim1 =
                  multiCaseDeviceParent?.rk.toString();
                multiCaseChildDevice.dim2 =
                  multiCaseDeviceParent?.dim2.toString();
                const newDim3 = cases?.items?.length
                  ? getNewDim3(cases, discoveryTableData)
                  : Number(multiCaseDeviceParent?.dim3) + 1;
                multiCaseChildDevice.dim3 = newDim3.toString();
                cases?.items?.push(caseTreeObj);
                caseTreeObj = null;
                return false;
              }
              return true;
            });
            if (!caseTreeObj) return false;
          }
          return true;
        });
        if (!caseTreeObj) return false;
      }
      return true;
    });
  }

  return {
    caseAssignedCompressorTree: compressorDataTreeToAssignCase,
    dimValueUpdatedDiscoveryTableData: discoveryTableData,
  };
};

export const getNewDim3 = (
  cases: TreeItem,
  discoveryTableData: DiscoveryTableData[],
) => {
  const lastCase = cases.items.at(-1);
  const lastCaseObj = discoveryTableData?.find(
    device => device?.deviceId === lastCase?.id,
  );
  const newDim3 = Number(lastCaseObj?.dim3) + 1;
  return newDim3;
};

export const findDeviceByDeviceId = (
  deviceId: number,
  discoveryTableData: DiscoveryTableData[],
) => discoveryTableData?.find(device => device.deviceId === deviceId);

export const checkIsAkIoAndConfigured = (selectedDevice: DiscoveryTableData) =>
  selectedDevice.deviceType === DEVICE_TYPE.CIRCUIT &&
  selectedDevice.cfg === '1';

export const getCurrentSelectedCaseToAssign = (
  currentSelectedCase: AvailableCasesTableData[],
  currentSelectedCaseDeviceIds: number[],
) => {
  let newlySelectedCasedeviceIds = null;
  const updatedDeviceIds = currentSelectedCase.map(cases => {
    return cases.deviceId;
  });
  updatedDeviceIds.every(caseDeviceIds => {
    if (!currentSelectedCaseDeviceIds.includes(caseDeviceIds)) {
      newlySelectedCasedeviceIds = caseDeviceIds;
      return false;
    }
    return true;
  });
  return currentSelectedCase?.find(
    caseIds => caseIds.deviceId === newlySelectedCasedeviceIds,
  );
};

export const getCurrentSelectedCaseToRemove = (
  currentSelectedCase: AvailableCasesTableData[],
  currentSelectedCaseDeviceIds: number[],
) => {
  let newlySelectedCasedeviceIds = null;

  const updatedDeviceIds = currentSelectedCase.map(cases => {
    return cases.deviceId;
  });
  currentSelectedCaseDeviceIds.every(caseDeviceIds => {
    if (!updatedDeviceIds.includes(caseDeviceIds)) {
      newlySelectedCasedeviceIds = caseDeviceIds;
      return false;
    }
    return true;
  });
  return newlySelectedCasedeviceIds;
};

export const getFromAndToGroupText = (
  fromPackGroup: string,
  fromSuctionGroup: string,
  fromCaseGroup: string,
  toPackGroup: string,
  toCaseGroup: string,
  toSuctionGroup: string,
) => {
  const defineToSuctionGroup = toSuctionGroup ? `->${toSuctionGroup}` : '';
  const definefromSuctionGroup = fromSuctionGroup
    ? `->${fromSuctionGroup}`
    : '';
  const definToCaseGroup = toCaseGroup ? `->${toCaseGroup}` : '';
  const definFromCaseGroup = fromCaseGroup ? `->${fromCaseGroup}` : '';
  const toGroup = toPackGroup + defineToSuctionGroup + definToCaseGroup || null;
  const fromGroup =
    fromPackGroup + definefromSuctionGroup + definFromCaseGroup || null;
  return {
    toDeviceGroup: toGroup,
    fromDeviceGroup: fromGroup,
  };
};

export const getCurrentOperation = (
  currentSelectedRowKeys: number[],
  selectedKeys: number[],
) => {
  const isRemoveOperation =
    currentSelectedRowKeys.length - selectedKeys.length === 1;

  const isAssignOperation =
    selectedKeys.length - currentSelectedRowKeys.length === 1;

  return {
    isRemoveOperation,
    isAssignOperation,
  };
};
