import '../utils/sidebar-shape.js';
import { SVG_PREFIX } from './mxgraph-constants.js';
import { parseSvgInImgSrc } from '../utils/parseSvgInImgSrc.js';
import '../utils/parse-xml.js';
import { isEncoded } from '../utils/is-encoded.js';
import 'pako';
import { decode } from '../utils/decode.js';
import '../utils/get-animated-spinner.js';
import { b as _defineProperty, a as _objectSpread2 } from '../_rollupPluginBabelHelpers-1525680e.js';
import { evalCode, customize } from './mxgraph.js';
import { MxSideBar } from './mxsidebar-class.js';
import { RuleContainer } from './ruleContainer-class.js';

const HIGHLIGHT_COLOR_DEFAULT = '#99ff33';
const IMG_KEYWORD = 'image=data:image/';
const IMG_BACKGROUND_PREFIX = 'shape=image;selectable=0;connectable=0;editable=0;movable=0;image=';
const MXGRAPH_DEFAULT_FONT_SZIE = '14';
const MXGRAPH_DEFAULT_FONT_COLOR = '#000';
const MXGRAPH_DEFAULT_BACKGROUND_COLOR = '#686868';
const FONT_SIZE_STEP = 1;
const backgroundUrl = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=';
// eslint-disable-next-line new-cap
const img = new Image();
img.src = backgroundUrl;
class MxGraph {
  constructor(container, xml) {
    _defineProperty(this, "graph", undefined);
    _defineProperty(this, "scale", true);
    _defineProperty(this, "tooltip", true);
    _defineProperty(this, "lock", true);
    _defineProperty(this, "center", true);
    _defineProperty(this, "animation", true);
    _defineProperty(this, "zoom", false);
    _defineProperty(this, "zoomFactor", 1.2);
    _defineProperty(this, "cumulativeZoomFactor", 1);
    _defineProperty(this, "grid", true);
    _defineProperty(this, "bgColor", null);
    _defineProperty(this, "zoomPercent", '1');
    _defineProperty(this, "isMouseDown", false);
    _defineProperty(this, "sbGraph", null);
    _defineProperty(this, "onDeleteCells", () => {});
    _defineProperty(this, "isMouseOnParameterCell", false);
    _defineProperty(this, "createSelectHandler", cbFunc => {
      this.graph.getSelectionModel().addListener(mxEvent.CHANGE, cbFunc);
    });
    this.container = container;
    this.mapping = {
      active: false
    };
    MxGraph.initMxGraph();
    if (isEncoded(xml)) {
      this.xml = decode(xml, true, true, true);
    } else {
      this.xml = xml;
    }
    this.init();
  }

  /**
   * Init Global vars an libs for mxgraph
   *
   * @static
   * @returns
   * @memberof XGraph
   */
  static initMxGraph() {
    const myWindow = window;
    if (!MxGraph.initialized) {
      if (myWindow.mxGraph === undefined || myWindow.mxGraph === undefined) {
        evalCode();
        customize();
      }
      MxGraph.initialized = true;
    }
  }
  init() {
    // eslint-disable-next-line new-cap
    this.graph = new Graph(this.container);
    this.graph.setPanning(true);
    this.graph.panningHandler.useLeftButtonForPanning = true;
    this.clickBackup = this.graph.click;
    this.dbclickBackup = this.graph.dblClick;
    this.isMouseOnParameterCell = false;
    mxEvent.addListener(this.container, 'mousedown', () => {
      this.isMouseDown = true;
    });
    mxEvent.addListener(this.container, 'mouseup', () => {
      this.isMouseDown = false;
    });

    // CTRL+MOUSEWHEEL
    mxEvent.addMouseWheelListener(mxUtils.bind(this, this.eventMouseWheel), this.container);
    if (mxClient.IS_IE || mxClient.IS_EDGE) {
      mxEvent.addListener(this.container, 'wheel', mxUtils.bind(this, this.eventMouseWheel));
    }

    // KEYS
    mxEvent.addListener(document, 'keydown', mxUtils.bind(this, this.eventKey));

    // CONTEXT MENU
    this.container.addEventListener('contextmenu', e => e.preventDefault());

    // DB CLICK
    this.graph.dblClick = this.eventDbClick.bind(this);
    this.highlightListener = null;

    // eslint-disable-next-line new-cap
    const undoManager = new mxUndoManager();
    this.graph.undoManager = undoManager;
    const listener = (_arg, evt) => {
      undoManager.undoableEditHappened(evt.getProperty('edit'));
    };
    this.graph.getModel().addListener(mxEvent.UNDO, listener);
    this.graph.getView().addListener(mxEvent.UNDO, listener);
    mxClipboard.cellsToString = cells => {
      // eslint-disable-next-line new-cap
      const codec = new mxCodec();
      // eslint-disable-next-line new-cap
      const model = new mxGraphModel();
      const parent = model.getChildAt(model.getRoot(), 0);
      for (let i = 0; i < cells.length; i++) {
        model.add(parent, cells[i]);
      }
      return mxUtils.getXml(codec.encode(model));
    };
    const textInput = document.createElement('textarea');
    mxUtils.setOpacity(textInput, 0);
    textInput.style.width = '1px';
    textInput.style.height = '1px';
    let restoreFocus = false;
    const gs = this.graph.gridSize;
    let lastPaste = null;
    let dx = 0;
    let dy = 0;
    textInput.value = ' ';
    mxEvent.addListener(document, 'keydown', evt => {
      const source = mxEvent.getSource(evt);
      if (this.graph.isEnabled() && !this.graph.isMouseDown && !this.graph.isEditing() && source.nodeName !== 'INPUT') {
        if (evt.keyCode === 224 /* FF */ || !mxClient.IS_MAC && evt.keyCode === 17 /* Control */ || mxClient.IS_MAC && (evt.keyCode === 91 || evt.keyCode === 93) /* Left/Right Meta */) {
          // Cannot use parentNode for check in IE
          if (!restoreFocus) {
            // Avoid autoscroll but allow handling of events
            textInput.style.position = 'absolute';
            textInput.style.left = `${this.graph.container.scrollLeft + 10}px`;
            textInput.style.top = `${this.graph.container.scrollTop + 10}px`;
            this.graph.container.appendChild(textInput);
            restoreFocus = true;
            textInput.focus();
            textInput.select();
          }
        }
      }
    });

    // Restores focus on graph container and removes text input from DOM
    mxEvent.addListener(document, 'keyup', evt => {
      if (restoreFocus && (evt.keyCode === 224 /* FF */ || evt.keyCode === 17 /* Control */ || evt.keyCode === 91 || evt.keyCode === 93) /* Meta */) {
        restoreFocus = false;
        if (!this.graph.isEditing()) {
          this.graph.container.focus();
        }
        textInput.parentNode.removeChild(textInput);
      }
    });

    // Inserts the XML for the given cells into the text input for copy
    const copyCells = (graph, cells) => {
      if (cells.length > 0) {
        const clones = graph.cloneCells(cells);
        // Checks for orphaned relative children and makes absolute
        for (let i = 0; i < clones.length; i++) {
          const state = graph.view.getState(cells[i]);
          if (state !== null) {
            const geo = graph.getCellGeometry(clones[i]);
            if (geo !== null && geo.relative) {
              geo.relative = false;
              geo.x = state.x / state.view.scale - state.view.translate.x;
              geo.y = state.y / state.view.scale - state.view.translate.y;
            }
          }
        }
        textInput.value = mxClipboard.cellsToString(clones);
      }
      textInput.select();
      lastPaste = textInput.value;
    };

    // Handles copy event by putting XML for current selection into text input
    mxEvent.addListener(this.container, 'copy', mxUtils.bind(this, _evt => {
      if (this.graph.isEnabled() && !this.graph.isSelectionEmpty()) {
        copyCells(this.graph, mxUtils.sortCells(this.graph.model.getTopmostCells(this.graph.getSelectionCells())));
        dx = 0;
        dy = 0;
      }
    }));

    // Handles cut event by removing cells putting XML into text input
    mxEvent.addListener(this.container, 'cut', mxUtils.bind(this, _evt => {
      if (this.graph.isEnabled() && !this.graph.isSelectionEmpty()) {
        copyCells(this.graph, this.graph.removeCells(this.graph.getSelectionCells(this.graph.getDefaultParent())));
        dx = -gs;
        dy = -gs;
      }
    }));

    // Merges XML into existing graph and layers
    const importXml = (xml, dx, dy) => {
      dx = dx !== null ? dx : 0;
      dy = dy !== null ? dy : 0;
      let cells = [];
      try {
        const doc = mxUtils.parseXml(xml);
        const node = doc.documentElement;
        if (node !== null) {
          const model = new mxGraphModel();
          const codec = new mxCodec(node.ownerDocument);
          codec.decode(node, model);
          const childCount = model.getChildCount(model.getRoot());
          const targetChildCount = this.graph.model.getChildCount(this.graph.model.getRoot());

          // Merges existing layers and adds new layers
          this.graph.model.beginUpdate();
          for (let i = 0; i < childCount; i++) {
            let parent = model.getChildAt(model.getRoot(), i);

            // Adds cells to existing layers if not locked
            if (targetChildCount > i) {
              // Inserts into active layer if only one layer is being pasted
              const target = childCount === 1 ? this.graph.getDefaultParent() : this.graph.model.getChildAt(this.graph.model.getRoot(), i);
              if (!this.graph.isCellLocked(target)) {
                const children = model.getChildren(parent);
                cells = cells.concat(this.graph.importCells(children, dx, dy, target));
              }
            } else {
              // Delta is non cascading, needs separate move for layers
              parent = this.graph.importCells([parent], 0, 0, this.graph.model.getRoot())[0];
              const children = this.graph.model.getChildren(parent);
              this.graph.moveCells(children, dx, dy);
              cells = cells.concat(children);
            }
          }
          this.graph.model.endUpdate();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw e;
      }
      return cells;
    };

    // Parses and inserts XML into graph
    const pasteText = text => {
      const xml = mxUtils.trim(text);
      if (xml.length > 0) {
        if (lastPaste !== xml) {
          lastPaste = xml;
          dx = 0;
          dy = 0;
        } else {
          dx += gs;
          dy += gs;
        }
        // Standard paste via control-v
        if (xml.substring(0, 14) === '<mxGraphModel>') {
          this.graph.setSelectionCells(importXml(xml, dx, dy));
          this.graph.scrollCellToVisible(this.graph.getSelectionCell());
        }
      }
    };
    mxClipboard.cellsToString = cells => {
      // eslint-disable-next-line new-cap
      let codec = new mxCodec();
      // eslint-disable-next-line new-cap
      let model = new mxGraphModel();
      const parent = model.getChildAt(model.getRoot(), 0);
      for (let i = 0; i < cells.length; i++) {
        model.add(parent, cells[i]);
      }
      return mxUtils.getXml(codec.encode(model));
    };

    // Cross-browser function to fetch text from paste events
    const extractGraphModelFromEvent = evt => {
      let data = null;
      if (evt !== null) {
        const provider = evt.dataTransfer != null ? evt.dataTransfer : evt.clipboardData;
        if (provider !== null) {
          if (document.DOCUMENT_NODE === 10 || document.DOCUMENT_NODE === 11) {
            data = provider.getData('Text');
          } else {
            data = mxUtils.indexOf(provider.types, 'text/html') >= 0 ? provider.getData('text/html') : null;
            if (mxUtils.indexOf(provider.types,  (data === null || data.length === 0))) {
              data = provider.getData('text/plain');
            }
          }
        }
      }
      return data;
    };

    // Handles paste event by parsing and inserting XML
    mxEvent.addListener(this.container, 'paste', evt => {
      // Clears existing contents before paste - should not be needed
      // because all text is selected, but doesn't hurt since the
      // actual pasting of the new text is delayed in all cases.
      textInput.value = '';
      if (this.graph.isEnabled()) {
        const xml = extractGraphModelFromEvent(evt);
        if (xml !== null && xml.length > 0) {
          pasteText(xml);
        } else {
          // Timeout for new value to appear
          window.setTimeout(mxUtils.bind(this, () => {
            pasteText(textInput.value);
          }), 0);
        }
      }
      textInput.select();
    });
    const style = new Object();
    style[mxConstants.STYLE_STROKECOLOR] = MXGRAPH_DEFAULT_FONT_COLOR;
    style[mxConstants.STYLE_FILLCOLOR] = '#FFFFFF';
    style[mxConstants.STYLE_FONTSIZE] = MXGRAPH_DEFAULT_FONT_SZIE;
    style[mxConstants.STYLE_STROKEWIDTH] = '0.5';
    this.graph.getStylesheet().putCellStyle('labelstyle', style);
    this.graph.getStylesheet().putDefaultVertexStyle(style);
    return this;
  }
  nudge(keyCode) {
    if (!this.graph.isSelectionEmpty()) {
      let dxx = 0;
      let dyy = 0;
      if (keyCode === 37) {
        dxx = -1;
      } else if (keyCode === 38) {
        dyy = -1;
      } else if (keyCode === 39) {
        dxx = 1;
      } else if (keyCode === 40) {
        dyy = 1;
      }
      this.graph.moveCells(this.graph.getSelectionCells(), dxx, dyy);
    }
    return this;
  }
  setFillColor(color) {
    this.graph.setCellStyles('fillColor', color, this.graph.getSelectionCells(this.graph.getDefaultParent()));
    this.refresh();
    return this;
  }
  setCellFontSize(cell, fontSize) {
    this.graph.setCellStyles(mxConstants.STYLE_FONTSIZE, fontSize, [cell]);
  }
  changeFontSize(up, rules) {
    const modifiedCells = Object.values(this.getMxCells()).filter(cell => cell.value);
    modifiedCells.forEach(cell => {
      const searchedRule = rules.find(rule => rule.mappings.mappings[0].shapeIdentifier === cell.id);
      const cellFontSize = parseInt(cell.style.split('fontSize=')[1]);
      const newFontSize = up ? cellFontSize + FONT_SIZE_STEP : cellFontSize - FONT_SIZE_STEP;
      searchedRule === null || searchedRule === void 0 ? void 0 : searchedRule.setStyles('fontSize', `${newFontSize}`);
      this.graph.setCellStyles(mxConstants.STYLE_FONTSIZE, `${newFontSize}`, [cell]);
      this.graph.updateCellSize(cell, true);
    });
    this.refresh();
    return this;
  }
  undo() {
    this.graph.undoManager.undo();
    return this;
  }
  redo() {
    this.graph.undoManager.redo();
    return this;
  }
  setOnDeleteHandler(func) {
    this.onDeleteCells = func;
    return this;
  }
  /**
   * Draw graph
   *
   * @returns {this}
   * @memberof XGraph
   */
  draw() {
    this.graph.getModel().beginUpdate();
    this.graph.getModel().clear();
    try {
      const xmlDoc = mxUtils.parseXml(this.xml);
      // eslint-disable-next-line new-cap
      const codec = new mxCodec(xmlDoc);
      this.graph.model.clear();
      this.graph.view.scale = 1;
      codec.decode(xmlDoc.documentElement, this.graph.getModel());
      this.graph.updateCssTransform();
      this.graph.selectUnlockedLayer();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in draw', error);
    } finally {
      this.graph.getModel().endUpdate();
    }
    return this;
  }

  /**
   * Apply options on graph
   *
   * @return this
   * @memberof XGraph
   */
  applyGraph() {
    if (!this.scale) {
      this.zoomGraph(this.zoomPercent);
    } else {
      this.unZoomGraph();
    }
    this.enableTooltip(this.tooltip);
    this.lockGraph(this.lock);
    if (this.scale && this.center) {
      this.fitGraph();
    } else {
      this.scaleGraph(this.scale);
      this.centerGraph(this.center);
    }
    this.gridGraph(this.grid);
    this.graph.foldingEnabled = true;
    this.graph.cellRenderer.forceControlClickHandler = true;
    this.sendBackgroundImageToBackward();
    this.refresh();
    return this;
  }
  createRule(options) {
    new RuleContainer(_objectSpread2({
      graph: this.graph
    }, options));
    return this;
  }
  sidebar(container) {
    // eslint-disable-next-line new-cap
    this.sbGraph = new MxSideBar(this.graph, container);
    this.sbGraph.init();
    return this;
  }
  getBackgroundCell() {
    const cells = this.getMxCells();
    const cellValues = Object.values(cells);
    const bgCell = cellValues.find(cell => {
      var _cell$getStyle;
      return (_cell$getStyle = cell.getStyle()) === null || _cell$getStyle === void 0 ? void 0 : _cell$getStyle.includes(IMG_KEYWORD);
    });
    return bgCell;
  }
  setBackgroundImage(src, width, height, keepPrevSize) {
    if (src) {
      const data = `${IMG_BACKGROUND_PREFIX}${src}`;
      this.graph.getModel().beginUpdate();
      const bgCell = this.getBackgroundCell();
      if (bgCell) {
        bgCell.setStyle(data);
        if (!keepPrevSize) {
          const geometry = new mxGeometry(0, 0, width, height);
          bgCell.setGeometry(geometry);
        }
      } else {
        const parent = this.graph.getDefaultParent();
        this.graph.insertVertex(parent, null, null, 0, 0, width, height, data);
      }
      this.graph.getModel().endUpdate();
    }
    return this;
  }
  sendBackgroundImageToBackward() {
    const cell = this.getBackgroundCell();
    this.graph.orderCells(true, [cell]);
    return this;
  }
  setSvgIconsInCell(cell, svgIcons) {
    var _this$graph$getChildV;
    if (!cell) return;
    const svgIconId = `${cell.id}-svg_icon`;
    const svgIconCell = (_this$graph$getChildV = this.graph.getChildVertices(cell)) === null || _this$graph$getChildV === void 0 ? void 0 : _this$graph$getChildV.find(v => v.id === svgIconId);
    // getting SVG from all icons within cell
    const cellIconsSvg = svgIconCell === null || svgIconCell === void 0 ? void 0 : svgIconCell.parent.children.map(svg => parseSvgInImgSrc(svg.style));
    if (svgIcons !== null && svgIcons !== void 0 && svgIcons.length) {
      if (!svgIconCell) {
        this.insertVertex(cell, svgIconId, svgIcons);
      } else if ((cellIconsSvg === null || cellIconsSvg === void 0 ? void 0 : cellIconsSvg.join()) !== svgIcons.join()) {
        this.graph.removeCells([svgIconCell], true);
        this.insertVertex(cell, svgIconId, svgIcons);
      }
    } else if (svgIconCell) {
      this.graph.removeCells([svgIconCell], true);
    }
  }
  insertVertex(cell, svgIconId, svgIcons) {
    svgIcons.forEach((icon, index) => {
      this.graph.insertVertex(cell, svgIconId, ``, index * -25 - 20, 0, 20, 20, `${SVG_PREFIX}${icon};`);
    });
  }
  setFontColor(cell, color) {
    this.graph.setCellStyles(mxConstants.STYLE_FONTCOLOR, color || MXGRAPH_DEFAULT_FONT_COLOR, [cell]);
    this.graph.setCellStyles(mxConstants.STYLE_STROKECOLOR, color || MXGRAPH_DEFAULT_FONT_COLOR, [cell]);
    this.graph.setCellStyles(mxConstants.STYLE_STROKEWIDTH, 1, [cell]);
  }
  setBackgroundColor(cell, isCellSelected, backgroundColor) {
    this.graph.setCellStyles(mxConstants.STYLE_FILLCOLOR, isCellSelected ? 'white' : backgroundColor || MXGRAPH_DEFAULT_BACKGROUND_COLOR, [cell]);
  }
  setTextsInGraph(cellUpdates) {
    this.graph.getModel().beginUpdate();
    for (const cellUpdate of cellUpdates) {
      const {
        cellId,
        svgIcons,
        value,
        color,
        isCellSelected,
        fontSize,
        backgroundColor
      } = cellUpdate;
      const cell = this.graph.getModel().getCell(cellId);
      this.setFontColor(cell, color);
      this.setBackgroundColor(cell, isCellSelected, backgroundColor);
      this.setCellFontSize(cell, fontSize);
      this.setSvgIconsInCell(cell, svgIcons);
      this.graph.getModel().setValue(cell, value);
      this.graph.updateCellSize(cell, true);
    }
    this.graph.getModel().endUpdate();
    this.refresh();
    return this;
  }
  deleteCells() {
    const cells = this.graph.getSelectionCells(this.graph.getDefaultParent());
    this.graph.removeCells(cells);
    this.onDeleteCells(cells);
    return this;
  }

  /**
   * Refresh graph
   *
   * @returns {this}
   * @memberof XGraph
   */
  refresh() {
    this.graph.refresh();
    return this;
  }

  /**
   * Return all cells
   *
   * @returns {Map<mxCell>} mxCells
   * @memberof XGraph
   */
  getMxCells() {
    return this.graph.getModel().cells;
  }

  /**
   * lock cells
   *
   * @returns {this}
   * @param {Boolean} bool
   * @memberof XGraph
   */
  lockGraph(bool) {
    if (bool) {
      this.graph.setEnabled(false);
      this.graph.panningHandler.ignoreCell = true;
    } else {
      this.graph.setEnabled(true);
      this.graph.panningHandler.ignoreCell = false;
    }
    this.lock = bool;
    return this;
  }

  /**
   * Enable tooltip
   *
   * @returns {this}
   * @param {Boolean} bool
   * @memberof XGraph
   */
  enableTooltip(bool) {
    if (bool) {
      this.graph.setTooltips(true);
    } else {
      this.graph.setTooltips(false);
    }
    this.tooltip = bool;
    return this;
  }

  /**
   * Center graph in panel
   *
   * @returns {this}
   * @param {Boolean} bool
   * @memberof XGraph
   */
  centerGraph(bool) {
    this.graph.centerZoom = false;
    if (bool) {
      this.graph.center(true, true);
    } else {
      this.graph.center(false, false);
    }
    this.center = bool;
    return this;
  }

  /**
   * Scale graph in panel
   *
   * @returns {this}
   * @param {boolean} bool
   * @memberof XGraph
   */
  scaleGraph(bool) {
    if (bool) {
      this.unZoomGraph();
      this.graph.fit();
      this.graph.view.rendering = true;
    }
    this.scale = bool;
    return this;
  }

  /**
   * Scale graph into container
   *
   * @returns {this}
   * @memberof XGraph
   */
  fitGraph() {
    const margin = 4;
    const max = 3;
    const bounds = this.graph.getGraphBounds();
    const cw = this.graph.container.clientWidth - margin;
    const ch = this.graph.container.clientHeight - margin;
    const w = bounds.width / this.graph.view.scale;
    const h = bounds.height / this.graph.view.scale;
    const s = Math.min(max, Math.min(cw / w, ch / h));
    this.graph.view.scaleAndTranslate(s, (margin + cw - w * s) / (2 * s) - bounds.x / this.graph.view.scale, (margin + ch - h * s) / (2 * s) - bounds.y / this.graph.view.scale);
    return this;
  }

  /**
   * Display grid in panel
   *
   * @param {boolean} bool
   * @returns {this}
   * @memberof XGraph
   */
  gridGraph(bool) {
    if (bool) {
      this.container.style.backgroundRepeat = 'repeat';
      this.container.style.backgroundPosition = '-1px -1px';
      this.container.style.backgroundImage = `url('${img.src}')`;
    } else {
      this.container.style.backgroundImage = '';
    }
    this.grid = bool;
    return this;
  }

  /**
   * Assign source definition and redraw graph
   *
   * @param {string} xmlGraph
   * @returns {this}
   * @memberof XGraph
   */
  setContent(content) {
    if (isEncoded(content)) {
      this.xml = decode(content, true, true, true);
    } else {
      this.xml = decode(content, false, false, false);
    }
    this.draw();
    return this;
  }

  /**
   * Assign new label for mxCell
   *
   * @param {mxCell} mxCell
   * @param {string} text - New label
   * @returns {this}
   * @memberof XGraph
   */
  setLabelCell(mxCell, text) {
    this.graph.cellLabelChanged(mxCell, text, false);
    return this;
  }

  /**
   * Zoom/un-zoom
   *
   * @param {string} percent
   * @returns {this}
   * @memberof XGraph
   */
  zoomGraph(percent) {
    if (!this.scale && percent && percent.length > 0 && percent !== '100%' && percent !== '0%') {
      const ratio = Number(percent.replace('%', '')) / 100;
      this.graph.zoomTo(ratio, true);
      this.zoomPercent = percent;
    } else {
      this.unZoomGraph();
    }
    this.zoom = true;
    return this;
  }

  /**
   * Restore initial size
   *
   * @returns {this}
   * @memberof XGraph
   */
  unZoomGraph() {
    this.zoom = false;
    this.graph.zoomActual();
    return this;
  }

  /**
   * Event for key on graph
   *
   * @param {KeyboardEvent} evt
   * @memberof XGraph
   */
  eventKey(evt) {
    if (!mxEvent.isConsumed(evt) && evt.keyCode === 27 /* Escape */) {
      this.cumulativeZoomFactor = 1;
      if (this.graph) {
        this.graph.zoomActual();
        this.applyGraph();
      }
    } else if (!mxEvent.isConsumed(evt) && evt.keyCode === 46) {
      this.deleteCells();
    } else if (!mxEvent.isConsumed(evt) && evt.keyCode === 90) {
      this.undo();
    } else if (!mxEvent.isConsumed(evt) && evt.keyCode === 89) {
      this.redo();
    } else if (!mxEvent.isConsumed(evt) && evt.keyCode === 37) {
      this.nudge(37);
    } else if (!mxEvent.isConsumed(evt) && evt.keyCode === 38) {
      this.nudge(38);
    } else if (!mxEvent.isConsumed(evt) && evt.keyCode === 39) {
      this.nudge(39);
    } else if (!mxEvent.isConsumed(evt) && evt.keyCode === 40) {
      this.nudge(40);
    }
  }

  /**
   * Event for mouse wheel on graph
   *
   * @param {Event} evt
   * @param {boolean} up
   * @memberof XGraph
   */
  eventMouseWheel(evt, up) {
    if (this.graph.isZoomWheelEvent(evt)) {
      if (up === null || up === undefined) {
        up = evt.deltaY < 0;
      }
      const rect = this.container.getBoundingClientRect();
      const x = evt.clientX - rect.left;
      const y = evt.clientY - rect.top;
      this.cumulativeZoomFactor *= up ? 1.2 : 0.8;
      this.lazyZoomPointer(this.cumulativeZoomFactor, x, y);
    } else {
      up ? this.graph.zoomIn() : this.graph.zoomOut();
    }
    mxEvent.consume(evt);
  }

  /**
   * Event for double click on graph
   *
   * @param {MouseEvent} evt
   * @param {mxCell} mxcell
   * @memberof XGraph
   */
  eventDbClick(_, mxcell) {
    if (mxcell !== undefined) {
      this.lazyZoomCell(mxcell);
    }
  }

  /**
   * Zoom/Un-zoom on graph on mouse pointer
   *
   * @param {number} factor
   * @param {number} offsetX
   * @param {number} offsetY
   * @memberof XGraph
   */
  async lazyZoomPointer(factor, offsetX, offsetY) {
    let dx = offsetX * 2;
    let dy = offsetY * 2;
    factor = Math.max(0.01, Math.min(this.graph.view.scale * factor, 160)) / this.graph.view.scale;
    factor = this.cumulativeZoomFactor / this.graph.view.scale;
    const scale = Math.round(this.graph.view.scale * factor * 100) / 100;
    factor = scale / this.graph.view.scale;
    if (factor > 1) {
      const f = (factor - 1) / (scale * 2);
      dx *= -f;
      dy *= -f;
    } else {
      const f = (1 / factor - 1) / (this.graph.view.scale * 2);
      dx *= f;
      dy *= f;
    }
    this.graph.view.scaleAndTranslate(scale, this.graph.view.translate.x + dx, this.graph.view.translate.y + dy);
  }

  /**
   * Zoom cell on full panel
   *
   * @param {mxCell} mxcell
   * @memberof XGraph
   */
  lazyZoomCell(mxcell) {
    if (mxcell !== undefined && mxcell !== null && mxcell.isVertex()) {
      const gap = 50;
      const state = this.graph.view.getState(mxcell);
      if (state !== null) {
        const {
          x,
          y,
          width,
          height
        } = state;
        const rect = new mxRectangle(x, y - gap, width, height + gap * 2);
        this.graph.zoomToRect(rect);
        this.cumulativeZoomFactor = this.graph.view.scale;
      }
    }
  }
  getGraph() {
    return this.graph;
  }
  highlightMxCell(mxCell, highlightColor) {
    if (highlightColor === void 0) {
      highlightColor = '';
    }
    if (!mxCell.hl) {
      const state = this.graph.view.getState(mxCell);
      if (state != null) {
        // eslint-disable-next-line new-cap
        const hl = new mxCellHighlight(this.graph, highlightColor || HIGHLIGHT_COLOR_DEFAULT, 1, false);
        hl.opacity = 100;
        hl.highlight(state);
        mxCell.hl = hl;
      }
    }
    this.changeCursorOnCellHover(mxCell);
    return mxCell;
  }
  changeCursorOnCellHover(mxCell) {
    const state = this.graph.view.getState(mxCell);
    if (mxCell != null) {
      if (state != null) {
        if (!state.cell.style.includes('shape=image')) {
          this.changeCursor('crosshair', false);
        } else {
          this.changeCursor('grab', true);
        }
      }
    }
    return mxCell;
  }
  changeCursor(cursor, flag) {
    this.graph.panningHandler.useLeftButtonForPanning = flag;
    this.graph.panningHandler.ignoreCell = flag;
    this.container.style.cursor = cursor;
    this.isMouseOnParameterCell = !flag;
    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  unHighlightMxCell(mxCell) {
    if (mxCell && mxCell.hl) {
      mxCell.hl.opacity = 0;
      mxCell.hl.destroy();
      mxCell.hl = null;
    }
    this.changeCursorOnCellHover(mxCell);
  }
  setMouseListener() {
    const _self = this;
    this.highlightListener = {
      currentCell: null,
      mouseMove(_, evt) {
        if (_self.mapping.active) {
          const mxCell = evt.getCell();
          if (mxCell) {
            if (this.currentCell !== mxCell) {
              this.currentCell && _self.unHighlightMxCell(this.currentCell);
              this.currentCell = _self.highlightMxCell(mxCell);
            }
          } else {
            _self.unHighlightMxCell(this.currentCell);
            this.currentCell = null;
          }
        }
        evt.consume();
      },
      mouseDown(_, evt) {
        if (_self.mapping.active) {
          _self.unHighlightMxCell(this.currentCell);
          this.currentCell = null;
        }
        evt.consume();
      },
      mouseUp(_, evt) {
        if (_self.mapping.active) {
          _self.unHighlightMxCell(this.currentCell);
          this.currentCell = null;
        }
        const mxCell = evt.getCell();
        if (mxCell && mxCell.hasLinkAction) {
          window.open(mxCell.linkActionUrl, '_blank');
        }
        evt.consume();
      }
    };
    this.graph.addMouseListener(this.highlightListener);
  }
  unsetMouseListener() {
    if (this.highlightListener) {
      this.graph.removeMouseListener(this.highlightListener);
    }
  }
  setGraphMap(cb, willUnsetGraphMap) {
    if (willUnsetGraphMap === void 0) {
      willUnsetGraphMap = true;
    }
    this.mapping.active = true;
    if (this.mapping.active) {
      this.container.style.cursor = this.isMouseOnParameterCell ? 'crosshair' : 'grab';
      this.graph.click = this.graphEventClick.bind(this, cb, willUnsetGraphMap);
      this.setMouseListener();
    }
  }
  unsetGraphMap() {
    this.mapping.active = false;
    this.graph.click = this.clickBackup;
    this.container.style.cursor = 'auto';
    this.unsetMouseListener();
  }
  graphEventClick(cb, willUnsetGraphMap, mxCell) {
    this.changeCursorOnCellHover(mxCell);
    if (this.mapping.active && mxCell) {
      const state = mxCell.getState();
      if (state) {
        cb(state);
      }
      willUnsetGraphMap && this.unsetGraphMap();
    }
  }
  zoomIn(ratio, center) {
    if (center === void 0) {
      center = true;
    }
    this.graph.zoomIn(ratio, center);
    this.zoomPercent = this._toPercent(ratio);
  }
  zoomOut(ratio, center) {
    if (center === void 0) {
      center = true;
    }
    this.graph.zoomOut(ratio, center);
    this.zoomPercent = this._toPercent(ratio);
  }
  getGraphXml() {
    // eslint-disable-next-line new-cap
    const encoder = new mxCodec();
    const result = encoder.encode(this.graph.getModel());
    return mxUtils.getXml(result);
  }

  // eslint-disable-next-line class-methods-use-this
  _toPercent(num) {
    return `${num * 100}%`;
  }
}
_defineProperty(MxGraph, "initialized", false);

export { IMG_BACKGROUND_PREFIX, IMG_KEYWORD, MXGRAPH_DEFAULT_BACKGROUND_COLOR, MXGRAPH_DEFAULT_FONT_COLOR, MXGRAPH_DEFAULT_FONT_SZIE, MxGraph };
