import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-4e4a2de3.js';
import { fetchXmlCsvFileData, XML_ACTION } from '@danfoss/etui-sm-xml';

const fetchCurrentSoftware = async _ref => {
  let [_key, user, url] = _ref;
  const currentData = await fetchXmlCsvFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.EXPORT_SW_VERSIONS,
    backup: 0
  });
  const rollbackData = await fetchXmlCsvFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.EXPORT_SW_VERSIONS,
    backup: 1
  });
  return currentData.map(current => {
    const rollbackversion = rollbackData.find(rollback => rollback.software === current.software);
    return _objectSpread2(_objectSpread2({}, current), {}, {
      rollbackversion: rollbackversion ? rollbackversion.version : null
    });
  });
};

export { fetchCurrentSoftware };
