import { STORAGE_METADATA_KEY } from '../constants/session-storage-constants.js';
import { SecurityMode } from '../types/Metadata.js';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-4b44363a.js';

const PORT_REG_EX = /:(\d){1,5}$/;
const getUnitsMetadata = () => JSON.parse(sessionStorage.getItem(STORAGE_METADATA_KEY) || '{}');
const getUnitMetadata = url => getUnitsMetadata()[url];
const updateUnitsMetadata = (url, metadtaFileContent) => {
  const unitsMetadata = getUnitsMetadata();
  sessionStorage.setItem(STORAGE_METADATA_KEY, JSON.stringify(_objectSpread2(_objectSpread2({}, unitsMetadata), {}, {
    [url]: metadtaFileContent
  })));
};
const shouldShowSecurityCompliancePopup = () => {
  const metadata = getUnitsMetadata();
  const metadtaFilesContent = Object.values(metadata);
  return metadtaFilesContent.some(fileContent => {
    var _fileContent$mode;
    return (fileContent === null || fileContent === void 0 ? void 0 : (_fileContent$mode = fileContent.mode) === null || _fileContent$mode === void 0 ? void 0 : _fileContent$mode.SecurityPopUpShown) === 'True';
  });
};
const getMetadataUnitUrlsWithPortNumbers = () => {
  const metadata = getUnitsMetadata();
  const metadataContent = Object.entries(metadata);
  const urlsWithPortNumbers = metadataContent.reduce((accumulator, _ref) => {
    var _unitMetadata$mode;
    let [url, unitMetadata] = _ref;
    return PORT_REG_EX.test(url) && unitMetadata !== null && unitMetadata !== void 0 && (_unitMetadata$mode = unitMetadata.mode) !== null && _unitMetadata$mode !== void 0 && _unitMetadata$mode.security ? accumulator.concat(url) : accumulator;
  }, []);
  return urlsWithPortNumbers;
};
const getUnitSecurityMode = url => {
  var _metadata$mode;
  const metadata = getUnitMetadata(url);
  return metadata === null || metadata === void 0 ? void 0 : (_metadata$mode = metadata.mode) === null || _metadata$mode === void 0 ? void 0 : _metadata$mode.security;
};
const isBackwardCompatible = url => {
  const mode = getUnitSecurityMode(url);
  return mode === SecurityMode.COMPATIBLE;
};

export { getMetadataUnitUrlsWithPortNumbers, getUnitMetadata, getUnitSecurityMode, getUnitsMetadata, isBackwardCompatible, shouldShowSecurityCompliancePopup, updateUnitsMetadata };
