import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { Seperator } from 'pages/InfoPage/components/Seperator';
import { StagingPatternTableData } from './types';
import { ConfigurationListItemEditStagingPatternCompressors } from './ConfigurationListItemEditStagingPatternCompressors';

export interface ConfigurationListItemEditStagingPatternTableProps {
  stagingPatternTableData: StagingPatternTableData[];
  filteredStagingPatternTableData: StagingPatternTableData[];
  emptyTableDataSource: boolean;
  handleCompressorClick: (rowIndex: number, compIndex: number) => void;
  handleUnloaderClick: (
    rowIndex: number,
    compIndex: number,
    unloaderIndex: number,
  ) => void;
}

export const ConfigurationListItemEditStagingPatternTable = ({
  stagingPatternTableData,
  filteredStagingPatternTableData,
  emptyTableDataSource,
  handleCompressorClick,
  handleUnloaderClick,
}: ConfigurationListItemEditStagingPatternTableProps) => {
  const [tableData, setTableData] = React.useState<StagingPatternTableData[]>(
    [],
  );

  React.useEffect(() => {
    if (emptyTableDataSource) {
      setTableData([]);
    } else if (
      stagingPatternTableData.length ||
      filteredStagingPatternTableData.length
    ) {
      setTableData(
        filteredStagingPatternTableData.length
          ? filteredStagingPatternTableData
          : stagingPatternTableData,
      );
    }
  }, [
    stagingPatternTableData,
    filteredStagingPatternTableData,
    emptyTableDataSource,
  ]);

  return (
    <Div testId="staging-pattern-table-div" ml="30px">
      {tableData.length
        ? tableData.map((stagingPattern, rowIndex) => {
            return (
              <>
                <Seperator />
                <Div
                  testId={`row${rowIndex}`}
                  display="flex"
                  flex={1}
                  flexDirection="row"
                >
                  <Div testId={`row-count-${rowIndex}`} mt="45px">
                    {stagingPattern.textCount}
                  </Div>
                  <Div
                    testId={`row-capacity-${rowIndex}`}
                    ml="20px"
                    mt="45px"
                    width="4%"
                  >
                    {stagingPattern.compressorUnloaderVisibiltyStatus.length !==
                      0 && stagingPattern.capacity}
                  </Div>
                  <Div
                    testId={`row-compressor-${rowIndex}`}
                    display="flex"
                    ml="40px"
                  >
                    <ConfigurationListItemEditStagingPatternCompressors
                      stagingPattern={stagingPattern}
                      rowIndex={rowIndex}
                      handleCompressorClick={handleCompressorClick}
                      handleUnloaderClick={handleUnloaderClick}
                    />
                  </Div>
                </Div>
              </>
            );
          })
        : null}
    </Div>
  );
};
