import '../utils/sidebar-shape.js';
import './mxgraph-constants.js';
import '../utils/parseSvgInImgSrc.js';
import '../utils/parse-xml.js';
import '../utils/is-encoded.js';
import 'pako';
import '../utils/decode.js';
import '../utils/get-animated-spinner.js';
import '../_rollupPluginBabelHelpers-1525680e.js';
import './mxgraph.js';
import './mxsidebar-class.js';
import './ruleContainer-class.js';
import { MxGraph, IMG_KEYWORD, IMG_BACKGROUND_PREFIX } from './mxgraph-class.js';

/* eslint-disable new-cap */
class MxHelper {
  constructor() {
    MxGraph.initMxGraph();
  }
  getBackgroundCell(model) {
    const {
      cells
    } = model;
    const cellValues = Object.values(cells);
    const bgCell = cellValues.find(cell => {
      var _cell$getStyle;
      return (_cell$getStyle = cell.getStyle()) === null || _cell$getStyle === void 0 ? void 0 : _cell$getStyle.includes(IMG_KEYWORD);
    });
    return bgCell;
  }
  setBackgroundImage(model, src, width, height, keepPrevSize) {
    if (keepPrevSize === void 0) {
      keepPrevSize = false;
    }
    if (src) {
      const style = `${IMG_BACKGROUND_PREFIX}${src}`;
      const bgCell = this.getBackgroundCell(model);
      if (bgCell) {
        bgCell.setStyle(style);
        if (!keepPrevSize) {
          const geometry = new mxGeometry(0, 0, width, height);
          bgCell.setGeometry(geometry);
        }
      } else {
        return false;
      }
    }
    return model;
  }
  createModelFromXml(xml) {
    const doc = mxUtils.parseXml(xml);
    const codec = new mxCodec(doc);
    return codec.decode(doc.documentElement);
  }
  transformModelIntoXml(model) {
    const encoder = new mxCodec();
    const result = encoder.encode(model);
    return mxUtils.getXml(result);
  }
}

export { MxHelper };
