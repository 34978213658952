import '../constants/session-storage-constants.js';
import { XML_ACTION } from '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import { AUTHORIZATION_HEADER, AUTH_TYPE } from '../types/Auth.js';
import '../types/Metadata.js';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import { parseXMLToJSON } from '../helpers/xml-helpers.js';
import { getSessionTokenByUrl } from '../helpers/session-token-helpers.js';
import { trimUrlEnding } from '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import { defineRequsetHeaders, removeUserDataFromBody } from '../helpers/header-helpers.js';
import { isAllowedServerError } from '../helpers/is-allowed-server-error.js';
import { createProxyUrl } from '../helpers/proxy-helpers.js';
import { XmlError } from '../helpers/xml-error.js';
import { getProxyConfig } from '../proxy-config.js';

const NOT_AUTHORIZED_STATUS_CODE = 401;
async function fetchXML(_ref, proxyConfig) {
  let {
    body,
    url,
    cfg
  } = _ref;
  if (proxyConfig === void 0) {
    proxyConfig = getProxyConfig();
  }
  const {
    proxyAddress,
    token,
    proxyEnabled
  } = proxyConfig;
  const urlEndpoint = proxyEnabled ? createProxyUrl(proxyConfig, url) : url;
  const authHeader = defineRequsetHeaders(url, body);
  if (proxyAddress && token) {
    authHeader[AUTHORIZATION_HEADER] = `${AUTH_TYPE.BEARER} ${token}`;
  }
  const sessionToken = getSessionTokenByUrl(trimUrlEnding(url));
  const req = {
    headers: _objectSpread2({
      'Content-Type': 'application/x-www-form-urlencoded'
    }, authHeader),
    method: 'POST',
    mode: 'cors',
    body: encodeURIComponent(sessionToken ? removeUserDataFromBody(body) : body)
  };
  const proxyReq = _objectSpread2(_objectSpread2({}, req), {}, {
    headers: _objectSpread2({
      'Content-Type': 'application/xml'
    }, authHeader),
    credentials: 'include'
  });
  const response = await fetch(urlEndpoint, proxyEnabled ? proxyReq : req);
  if (!response.ok && response.status !== NOT_AUTHORIZED_STATUS_CODE) {
    throw new Error(response.statusText);
  }
  let text = await response.text();
  if (text.includes(XML_ACTION.READ_CONFIGURE_STATUS)) {
    text = text.replace('<error>0</error>', '');
  }
  const json = await parseXMLToJSON(text);
  const {
    error,
    operation
  } = json.resp || {};
  const errorCode = Number(error);
  const isAllowedError = isAllowedServerError(error, cfg === null || cfg === void 0 ? void 0 : cfg.allowedErrorCodes);
  if (errorCode > 0 && !isAllowedError || operation === 'Failed') {
    throw new XmlError({
      code: errorCode,
      message: json.resp._ || json.resp.message || json.resp.error_msg || `Xml error code (${errorCode})`
    });
  }
  if (errorCode === 0 || isAllowedError) {
    return json.resp;
  }
  throw new Error('Unexpected response');
}

export { fetchXML };
