import '../../constants/session-storage-constants.js';
import '../../constants/xml-action-constant.js';
import '../../constants/xml-tag-constant.js';
import { getAlarmSummaryRefsByKey } from '../../helpers/alarm-helpers.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import '../../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import { fetchAlarmSummary } from './fetch-alarm-summary.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import '../../fetch-xml/fetch-xml-data.js';

/**
 * Get alarm refs by alarm type
 *
 * @param {string} url
 * @param {AlarmType} alarmType
 * @param {User} user
 * @returns {Promise<string[]>}
 */
async function fetchAlarmRefsByType(url, alarmType, user, skipSessionUpdate) {
  const alarmSummaryData = await fetchAlarmSummary(url, user, skipSessionUpdate);
  const alarmRefs = getAlarmSummaryRefsByKey(alarmSummaryData, alarmType);
  if (alarmRefs.length === 0) {
    return [];
  }
  return alarmRefs;
}

export { fetchAlarmRefsByType };
