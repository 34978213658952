import '../../_rollupPluginBabelHelpers-1525680e.js';
import { Subscribable } from '../utils/Subscribable.js';

class Display extends Subscribable {
  get unit() {
    return this._unit;
  }
  set unit(nextUnit) {
    this._unit = nextUnit;
    this.notify();
  }
  get parameterName() {
    return this._parameterName;
  }
  set parameterName(nextParameterName) {
    this._parameterName = nextParameterName;
    this.notify();
  }
  get assetName() {
    return this._assetName;
  }
  set assetName(nextAssetName) {
    this._assetName = nextAssetName;
    this.notify();
  }
  get address() {
    return this._address;
  }
  set address(nextAddress) {
    this._address = nextAddress;
    this.notify();
  }
  constructor() {
    super();
    this.assetName = false;
    this.parameterName = false;
    this.unit = false;
    this.address = false;
  }
  import(rawObj) {
    if (rawObj.assetName) {
      this.assetName = rawObj.assetName;
    }
    if (rawObj.parameterName) {
      this.parameterName = rawObj.parameterName;
    }
    if (rawObj.unit) {
      this.unit = rawObj.unit;
    }
    if (rawObj.address) {
      this.address = rawObj.address;
    }
    return this;
  }
  toJSON() {
    return {
      assetName: this.assetName,
      parameterName: this.parameterName,
      unit: this.unit,
      address: this.address
    };
  }
}

export { Display };
