import { RefrigerationSchedules } from 'pages/SchedulesPage/SchedulesPage.types';
import { getScheduleDayFromLetter } from 'pages/SchedulesPage/utils';

export const getScheduleDates = (
  daysOfTheweek: string[],
  { days, weekdays }: RefrigerationSchedules,
): string[] =>
  (days || weekdays)
    .split('')
    .map((d, index) => getScheduleDayFromLetter(daysOfTheweek, d, index))
    .filter(Boolean);

export const getScheduleDatesFromIndexes = (
  daysOfTheweek: string[],
  mapIndexes: (number | '-')[],
): string[] => mapIndexes.map(value => daysOfTheweek[value]).filter(Boolean);
