import { HistoryCfg } from '@danfoss/etui-sm-xml';
import _flatMap from 'lodash.flatmapdeep';
import { Dtype, Ptype, UnitsConfig } from '../HistoryPage.types';

const HISTORY_SEGMENT = 528;
const DATA_SIZE = 512;
const TIME_SIZE = 0x04;

const CDC_REPEAT_1BYTE = 125; // (125) repeat prev value (next 1 byte  = count) CDC: Compressed Data code
const CDC_REPEAT_2BYTE = 126; // (126) repeat prev value (next 2 byte  = count) CDC: Compressed Data code
const CDC_REPEAT_4BYTE = 127; // (127) repeat prev value (next 4 byte  = count) CDC: Compressed Data code
const CDC_NEW_VALUE = -128; // (128) new value follows (1, 2, or 4 bytes) CDC: Compressed Data code
const CDC_MISS_VALUE = -127; // (129) missing sample (gap in collection) CDC: Compressed Data code
const CDC_OFFL_VALUE = -126; // (130) node was offline at sample time CDC: Compressed Data code
const CDC_SUSP_VALUE = -125; // (131) history was suspended at sample time CDC: Compressed Data code

const compressionCodeToValMap = {
  [CDC_REPEAT_1BYTE]: true,
  [CDC_REPEAT_2BYTE]: true,
  [CDC_REPEAT_4BYTE]: true,
  [CDC_MISS_VALUE]: true,
  [CDC_OFFL_VALUE]: true,
  [CDC_SUSP_VALUE]: true,
};

const LUX_FC_CONV_FACT = 10.763909999607376; // convert factor from Foot candle to Lux.

function range(to: number) {
  return Array(to)
    .fill('magic')
    .map((_, i) => i);
}

function getSegment(data: ArrayBuffer, index: number) {
  return data.slice(index * HISTORY_SEGMENT, (index + 1) * HISTORY_SEGMENT);
}

function getSegmentHistoryIndex(segment: ArrayBuffer) {
  return `${new DataView(segment).getInt16(DATA_SIZE + 12)}`;
}

function getIsCompression(n: number): boolean {
  if (n > 0) {
    return n >= CDC_REPEAT_1BYTE && n <= CDC_REPEAT_4BYTE;
  }
  return n <= CDC_SUSP_VALUE && n >= CDC_NEW_VALUE;
}

// offset is 0 for segment starting from realData and not compression code
function getValue(segment: ArrayBuffer, param: HistoryCfg, offset: number = 1) {
  switch (Number(param.dtype)) {
    case Dtype.DB_BYTE:
      return new DataView(segment, offset, 1).getInt8(0);
    case Dtype.DB_WORD:
    case Dtype.DB_FLOAT:
    case Dtype.DB_SWORD:
      return new DataView(segment, offset, 2).getInt16(0);
    case Dtype.DB_INT:
    case Dtype.DB_FLOAT4:
    case Dtype.DB_FLOAT15:
      return new DataView(segment, offset, 4).getInt32(0);
    default:
      return 0;
  }
}

function getCompressedDataLength(compressionCode: number, param: HistoryCfg) {
  switch (compressionCode) {
    case CDC_REPEAT_1BYTE: {
      return 1;
    }
    case CDC_REPEAT_2BYTE: {
      return 2;
    }
    case CDC_REPEAT_4BYTE: {
      return 4;
    }
    case CDC_NEW_VALUE: {
      return getCompressedLengthForRealValue(param);
    }
    case CDC_MISS_VALUE:
    case CDC_OFFL_VALUE:
    case CDC_SUSP_VALUE:
    default:
      return 0;
  }
}

function getCompressedLengthForRealValue(param: HistoryCfg) {
  switch (Number(param.dtype)) {
    case Dtype.DB_BYTE: {
      return 1;
    }
    case Dtype.DB_WORD:
    case Dtype.DB_FLOAT:
    case Dtype.DB_SWORD: {
      return 2;
    }
    case Dtype.DB_INT:
    case Dtype.DB_FLOAT4:
    case Dtype.DB_FLOAT15: {
      return 4;
    }
    default: {
      return 0;
    }
  }
}

function convertUnitValue(
  value: number,
  ptype: number,
  { isCelsius, isBar, isLux }: UnitsConfig,
) {
  let val: number = 0;
  switch (ptype) {
    case Ptype.PTYPE_F2DEGF: {
      val = value / 10.0;
      if (isCelsius) {
        val = ((val - 32) * 5.0) / 9.0;
      }
      return Number(val.toFixed(2));
    }
    case Ptype.PTYPE_F2DEGFD: {
      val = value / 10.0;
      if (isCelsius) {
        val = (val * 5.0) / 9.0;
      }
      return Number(val.toFixed(2));
    }
    case Ptype.PTYPE_F2PSI: {
      val = value / 10.0;
      if (isBar) {
        val /= 14.7;
      }
      return Number(val.toFixed(2));
    }
    case Ptype.PTYPE_FLOAT2:
    case Ptype.PTYPE_F2PCT:
    case Ptype.PTYPE_F2VOLT:
    case Ptype.PTYPE_F2AMP:
    case Ptype.PTYPE_F2KW:
    case Ptype.PTYPE_F2KWH:
    case Ptype.PTYPE_F2HZ:
    case Ptype.PTYPE_FLOAT4:
    case Ptype.PTYPE_F4KW:
    case Ptype.PTYPE_F4KWH:
      return Number((value / 10.0).toFixed(2));
    case Ptype.PTYPE_F2FC:
      if (isLux) {
        return Number(((value * LUX_FC_CONV_FACT) / 10).toFixed(2));
      }
      return Number((value / 10.0).toFixed(2));
    default:
      return value;
  }
}

function getCompressedDataCount(segment: ArrayBuffer, compressionCode: number) {
  let dataViewCompressedByteBuffer: any;
  switch (compressionCode) {
    case CDC_REPEAT_1BYTE: {
      dataViewCompressedByteBuffer = new DataView(segment, 1, 0x01);
      let value = dataViewCompressedByteBuffer.getInt8(0);
      if ((value & 0x80) === 0x80) {
        value ^= 0xff;
        value += 1;
        value = -value;
      }
      return value;
    }
    case CDC_REPEAT_2BYTE: {
      dataViewCompressedByteBuffer = new DataView(segment, 1, 0x02);
      let value = dataViewCompressedByteBuffer.getInt16(0);
      if ((value & 0x8000) === 0x8000) {
        value ^= 0xffff;
        value += 1;
        value = -value;
      }
      return value;
    }
    case CDC_REPEAT_4BYTE: {
      dataViewCompressedByteBuffer = new DataView(segment, 1, 0x04);
      let value = dataViewCompressedByteBuffer.getInt32(0);
      if ((value & 0x8000) === 0x80000000) {
        value ^= 0xffffffff;
        value += 1;
        value = -value;
      }
      return value;
    }
    case CDC_NEW_VALUE:
    case CDC_MISS_VALUE:
    case CDC_OFFL_VALUE:
    case CDC_SUSP_VALUE:
    default: {
      return 0x00;
    }
  }
}

function getRealValue(
  segment: ArrayBuffer,
  param: HistoryCfg,
  compressionCode: number,
  value: number,
) {
  if (compressionCode === CDC_NEW_VALUE) {
    value = getValue(segment, param);
  } else if (!compressionCodeToValMap[compressionCode]) {
    value += compressionCode;
  }
  return value;
}

function getConvertedValue(
  param: HistoryCfg,
  value: number,
  unitsConfig: UnitsConfig,
) {
  if (param.digital === '1') {
    return value;
  }
  return convertUnitValue(value, Number(param.ptype), unitsConfig);
}

function getCompressedData({
  startTime,
  dataValue,
  compressedDataCount,
  sampleRate,
}) {
  return range(compressedDataCount).map(i => ({
    time: startTime + i * Number(sampleRate) * 1e3,
    dataValue,
  }));
}

function getPageData(
  segment: ArrayBuffer,
  param: HistoryCfg,
  unitsConfig: UnitsConfig,
) {
  const { mSecsOffset255toPC: offset, actual_sample_rate: sampleRate } = param;
  let dataView = new DataView(segment);
  let segmentStartTime =
    dataView.getInt32(DATA_SIZE + TIME_SIZE * 1) * 1e3 - (offset || 0);
  const segmentEndTime =
    dataView.getInt32(DATA_SIZE + TIME_SIZE * 2) * 1e3 - (offset || 0);
  const isCompressionCode = getIsCompression(dataView.getInt8(0));
  let realValue = 0;
  if (!isCompressionCode) {
    const compressedDataLen = getCompressedLengthForRealValue(param);
    realValue = getValue(segment, param, 0);
    segment = segment.slice(compressedDataLen, segment.byteLength);
    dataView = new DataView(segment);
  }
  const historyDataValuesArray = [];
  while (segmentStartTime < segmentEndTime && segment.byteLength) {
    dataView = new DataView(segment);
    const compressionCode = dataView.getInt8(0);
    const compressedDataLength = getCompressedDataLength(
      compressionCode,
      param,
    );
    const compressedDataCount = getCompressedDataCount(
      segment,
      compressionCode,
    );

    realValue = getRealValue(segment, param, compressionCode, realValue);
    const dataValue = getConvertedValue(param, realValue, unitsConfig);
    if (compressedDataCount) {
      historyDataValuesArray.push(
        ...getCompressedData({
          startTime: segmentStartTime,
          dataValue,
          compressedDataCount,
          sampleRate,
        }),
      );
      segmentStartTime += Number(sampleRate) * 1e3 * compressedDataCount;
    } else {
      historyDataValuesArray.push({
        time: segmentStartTime,
        dataValue:
          compressionCode !== CDC_MISS_VALUE &&
          compressionCode !== CDC_OFFL_VALUE &&
          compressionCode !== CDC_SUSP_VALUE
            ? dataValue
            : undefined, // for draw empty space on chart.
      });
      segmentStartTime += Number(sampleRate) * 1000;
    }
    segment = segment.slice(compressedDataLength + 1, segment.byteLength);
  }
  return historyDataValuesArray;
}

function processHistoryData(
  data: ArrayBuffer,
  params: HistoryCfg[],
  unitsConfig: UnitsConfig,
) {
  const numberOfSegments = data.byteLength / HISTORY_SEGMENT;

  const segmentsByHistoryIndex = range(numberOfSegments).reduce<{
    [key: number]: ArrayBuffer[];
  }>((acc, segmentIndex) => {
    const segment = getSegment(data, segmentIndex);
    const historyIndex = getSegmentHistoryIndex(segment);
    acc[historyIndex] = acc[historyIndex]
      ? acc[historyIndex].concat(segment)
      : [segment];
    return acc;
  }, {});

  return params.reduce((plotData, param) => {
    const historyIndex = param.hist_index;
    const segments = segmentsByHistoryIndex[historyIndex];
    if (segments) {
      plotData.push({
        param,
        data: segments.reduceRight<ArrayBuffer[]>(
          (acc, segment) =>
            acc.concat(getPageData(segment, param, unitsConfig)),
          [],
        ),
      });
    }
    return plotData;
  }, []);
}

export function processData(
  rawData: ArrayBuffer[],
  params: HistoryCfg[],
  config: UnitsConfig,
) {
  return _flatMap(rawData, queryData =>
    processHistoryData(queryData, params, config),
  );
}
