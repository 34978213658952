import React from 'react';
import {
  ButtonProps,
  SelectedValueType,
  TreeTableItem,
} from '@danfoss/etui-core';
import { ConfigurationListItem, Unit } from '@danfoss/etui-sm-xml';
import {
  CircuitsTableData,
  DeviceCompressorData,
  DeviceConstraintsData,
  DeviceTypeModelList,
  DiscoveryDeviceStatusCnt,
  DiscoveryTableData,
  RefrigSteps,
} from '../types/DiscoveryPage.types';
import { AvailableCasesTableData } from '../types/RefrigLayout.types';
import { PI200TableData } from '../types';

export type RefrigLayoutFlow =
  | 'network'
  | 'discovery'
  | 'compressors'
  | 'circuits'
  | 'suctiongroups';

export type Step =
  | 'network'
  | 'discovery'
  | 'compressors'
  | 'circuits'
  | 'suctiongroups';

export interface RefrigLayoutContextProps {
  flow: RefrigLayoutFlow;
  setFlow: (newFlow: RefrigLayoutFlow) => void;
  step?: number;
  buttonsByStep: { [key: string]: ButtonProps[] };
  nextStep?: Step;
  stepNumber?: number;
  totalStepsNumber?: number;
  setStep: (step: number) => void;
  setButtons: (newButtons: ButtonProps[]) => void;
  deviceConstraintsData?: DeviceConstraintsData;
  setDeviceConstraintsData?: (
    deviceConstraintsData: DeviceConstraintsData,
  ) => void;
  caseTypes?: string[];
  setCaseTypes?: (caseTypes: string[]) => void;
  circuitsData: CircuitsTableData[];
  setCircuitsData: (circuitsData: CircuitsTableData[]) => void;
  circuitsTableData: DiscoveryTableData[];
  setCircuitsTableData: (circuitsData: DiscoveryTableData[]) => void;
  discoveryTableData?: DiscoveryTableData[];
  setDiscoveryTableData?: (discoveryTableData: DiscoveryTableData[]) => void;
  filteredTableData?: DiscoveryTableData[];
  setFilteredTableData?: (filteredTableData: DiscoveryTableData[]) => void;
  isOfflineProgramming?: boolean;
  setIsOfflineProgramming?: (isOfflineProgramming: boolean) => void;
  discoveryDeviceStatusCnt?: DiscoveryDeviceStatusCnt;
  setDiscoveryDeviceStatusCnt?: (
    discoveryDeviceStatusCnt: DiscoveryDeviceStatusCnt,
  ) => void;
  activeStepNumber?: number;
  setActiveStepNumber?: (activeStepNumber: number) => void;
  refrigSteps?: RefrigSteps[];
  setRefrigSteps?: (refrigSteps: RefrigSteps[]) => void;
  unit: Unit;
  setUnit: (unit: Unit) => void;
  isTableRefreshing?: boolean;
  setIsTableRefreshing?: (isTableRefreshing: boolean) => void;
  deviceTypeModelList?: DeviceTypeModelList;
  setDeviceTypeModelList?: (deviceTypeModelList: DeviceTypeModelList) => void;
  deviceCompressorData?: DeviceCompressorData;
  setDeviceCompressorData?: (
    DeviceCompressorData: DeviceCompressorData,
  ) => void;
  selectedCompressorDeviceId?: number;
  setSelectedCompressorDeviceId?: (selectedCompressorDeviceId: number) => void;
  selectedCompressorRowIndex?: number;
  setSelectedCompressorRowIndex?: (selectedCompressorRowInex: number) => void;
  currentEditingField?: string;
  setCurrentEditingField?: (currentEditingField: string) => void;
  circuitTypeModelList?: DeviceTypeModelList;
  setCircuitTypeModelList?: (deviceTypeModelList: DeviceTypeModelList) => void;
  isHvac?: boolean;
  setIsHvac?: (isHvac: boolean) => void;
  hvacDeviceTypeModelList?: DeviceTypeModelList;
  setHvacDeviceTypeModelList?: (
    hvacDeviceTypeModelList: DeviceTypeModelList,
  ) => void;
  hvacFinalTableData?: DiscoveryTableData[];
  setHvacFinalTableData?: (hvacFinalTableData: DiscoveryTableData[]) => void;
  skipPI200: boolean;
  setSkipPI200: (skipPI200: boolean) => void;
  networkListData: any;
  setNetworkListData: (networkListData: any) => void;
  isHvacLayout: boolean;
  setIsHvacLayout: (isHvacLayout: boolean) => void;
  currentSelectedCaseDeviceIds: number[];
  setCurrentSelectedCaseDeviceIds: (
    currentSelectedCaseDeviceIds: number[],
  ) => void;
  compressorControlData: TreeTableItem[];
  setCompressorControlData: (compressorControlData: TreeTableItem[]) => void;
  availableCasesData: AvailableCasesTableData[];
  setAvailableCasesData: (
    availableCasesData: AvailableCasesTableData[],
  ) => void;
  currentSectionsOpen: SelectedValueType[];
  setCurrentSectionsOpen: (currentSectionsOpen: SelectedValueType[]) => void;
  currentSelectedCase?: AvailableCasesTableData;
  setCurrentSelectedCase?: (
    currentSelectedCase: AvailableCasesTableData,
  ) => void;
  allowMultiCaseCreation?: boolean;
  setAllowMultiCaseCreation?: (allowMultiCaseCreation: boolean) => void;
  currentSearchInputText?: string;
  setCurrentSearchInputText?: (currentSearchInputText: string) => void;
  searchedAvailableCasesData: AvailableCasesTableData[];
  setSearchedAvailableCasesData: (
    searchedAvailableCasesData: AvailableCasesTableData[],
  ) => void;
  currentSelectedRowKeys: number[];
  setCurrentSelectedRowKeys: (currentSelectedRowKeys: number[]) => void;
  isCaseAssignOperation?: boolean;
  setIsCaseAssignOperation?: (isCaseAssignOperation: boolean) => void;
  isCaseRemoveOperation?: boolean;
  setIsCaseRemoveOperation?: (isCaseRemoveOperation: boolean) => void;
  pi200TableData: PI200TableData;
  setPI200TableData: (pi200TableData: PI200TableData) => void;
  selectAll: ConfigurationListItem[];
  setSelectAll: (selectAll: ConfigurationListItem[]) => void;
  notAuth: ConfigurationListItem;
  setNotAuth: (notAuth: ConfigurationListItem) => void;
  selectedCompressorTreeDeviceId?: number;
  setSelectedCompressorTreeDeviceId?: (
    selectedCompressorDeviceId: number,
  ) => void;
  isPackRemoveOperation?: boolean;
  setIsPackRemoveOperation?: (isPackRemoveOperation: boolean) => void;
}

const RefrigLayoutContext = React.createContext<RefrigLayoutContextProps>(null);

export { RefrigLayoutContext };
