import { TFunction } from 'i18next';
import {
  DEVICE_TYPE,
  DiscoveryTableData,
  DeviceConstraintsData,
  DEVICE_STATUS,
  DEVICE_PACK_SUCTION_NAME,
  DEVICE_CATEGORY,
} from '../types/DiscoveryPage.types';
import {
  getDescriptionBasedOnType,
  getDeviceIndex,
  getNextSuctionGroupNumber,
  getRackCount,
  getSuctionGroupCount,
  isCircuitUnderNewSuctionBeforeCreation,
} from '../utils';

function copyCompressor(
  copyAmount: number,
  selectedRowIndex: number,
  discoveryTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  deviceSuction: DiscoveryTableData[],
  t: TFunction,
) {
  return copyDevice(
    copyAmount,
    selectedRowIndex,
    discoveryTableData,
    deviceConstraintsData,
    deviceSuction,
    t,
  );
}

const copyDevice = (
  copyAmount: number,
  selectedRowIndex: number,
  discoveryTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  deviceSuction: DiscoveryTableData[],
  t: TFunction,
): DiscoveryTableData[] => {
  if (selectedRowIndex < 0 || selectedRowIndex >= deviceSuction?.length) {
    selectedRowIndex = 0;
  }
  for (let index = 0; index < copyAmount; index++) {
    copySuction(
      selectedRowIndex,
      discoveryTableData,
      deviceConstraintsData,
      deviceSuction,
      t,
    );
  }
  return discoveryTableData;
};

const copySuction = (
  selectedRowIndex: number,
  discoveryTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  deviceSuction: DiscoveryTableData[],
  t: TFunction,
) => {
  if (
    getRackCount(discoveryTableData) >= +deviceConstraintsData?.max_rk ||
    getSuctionGroupCount(deviceSuction) >= +deviceConstraintsData?.max_sg
  ) {
    return;
  }
  const selectedDevice: DiscoveryTableData = deviceSuction[selectedRowIndex];
  const copiedDeviceData: DiscoveryTableData = {
    deviceId: getDeviceIndex(discoveryTableData) + 1,
    rk: deviceSuction?.length + 1,
    sg: getNextSuctionGroupNumber(discoveryTableData),
    ip: '',
    max_sg: selectedDevice?.max_sg,
    address: selectedDevice?.address !== '-' ? '0' : selectedDevice?.address,
    cfg: '0',
    online: '0',
    name:
      selectedDevice?.deviceType === DEVICE_TYPE.RACK
        ? `${t('t479')} ${String.fromCharCode(65 + deviceSuction?.length)}`
        : selectedDevice?.deviceType === DEVICE_TYPE.GEN_PACK
        ? `${t('t188')} ${deviceSuction?.length + 1}`
        : selectedDevice?.deviceType === DEVICE_TYPE.NO_COMP
        ? `${t('t480')} ${String.fromCharCode(65 + deviceSuction?.length)}`
        : `${selectedDevice?.name} ${deviceSuction?.length + 1}`,
    removed: false,
    newDevice: true,
    category: selectedDevice?.category,
    type: selectedDevice?.type,
    deviceType: selectedDevice?.deviceType,
    model: selectedDevice?.model,
    file: selectedDevice?.file,
    version: selectedDevice?.version,
    device: selectedDevice?.device,
    listname: selectedDevice?.listname,
    model_idx: selectedDevice?.model_idx,
    status: DEVICE_STATUS.RED,
    case_type: selectedDevice?.case_type,
    deviceBus: selectedDevice?.deviceBus,
    mx_naddr: selectedDevice?.mx_naddr,
    num_sg: 1,
    code: selectedDevice?.code,
    assigned: false,
  };
  discoveryTableData.push(copiedDeviceData);

  discoveryTableData.forEach(device => {
    if (
      isCircuitUnderNewSuctionBeforeCreation(device, deviceSuction?.length + 1)
    ) {
      delete device.rk;
    }
  });

  deviceSuction.push(copiedDeviceData);

  insertSuctionGroupBasedOnType(
    copiedDeviceData,
    discoveryTableData,
    deviceConstraintsData,
    t,
  );
};

const insertSuctionGroupBasedOnType = (
  copiedDeviceData: DiscoveryTableData,
  discoveryTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
) => {
  let name: string = '';
  if (
    copiedDeviceData?.deviceType === DEVICE_TYPE.RACK ||
    copiedDeviceData?.deviceType === DEVICE_TYPE.NO_COMP
  ) {
    name = `${t('t402')} ${
      String.fromCharCode(65 + copiedDeviceData?.rk - 1) +
      String.fromCharCode(65)
    }`;
  } else if (copiedDeviceData?.deviceType === DEVICE_TYPE.GEN_PACK) {
    name = `${t('t402')} ${DEVICE_PACK_SUCTION_NAME[0]}`;
  }
  insertSuctionGroup(
    copiedDeviceData?.rk,
    name,
    false,
    discoveryTableData,
    deviceConstraintsData,
    t,
  );
};

function insertSuctionGroup(
  rk: number,
  name: string,
  advanvceSg: boolean,
  discoveryTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
) {
  const insertTodeviceType: string = getDeviceTypeToInsert(
    discoveryTableData,
    rk,
  );
  createSuctions(
    discoveryTableData,
    insertTodeviceType,
    rk,
    name,
    advanvceSg,
    deviceConstraintsData,
    t,
  );
}

const createSuctions = (
  discoveryTableData: DiscoveryTableData[],
  insertTodeviceType: string,
  rk: number,
  name: string,
  advanceSg: boolean,
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
) => {
  const total: number = discoveryTableData.length;
  for (let i = total - 1; i >= 0; i--) {
    const device: DiscoveryTableData = discoveryTableData[i];
    if (
      (device.deviceType === DEVICE_TYPE.GEN_PACK ||
        device.deviceType === DEVICE_TYPE.RACK ||
        device.deviceType === DEVICE_TYPE.NO_COMP ||
        device.deviceType === DEVICE_TYPE.RACKSUCT ||
        device.deviceType === DEVICE_TYPE.CIRCUIT ||
        device.deviceType === DEVICE_TYPE.CIRCUIT) &&
      (insertTodeviceType === DEVICE_TYPE.GEN_PACK ||
        insertTodeviceType === DEVICE_TYPE.RACK ||
        insertTodeviceType === DEVICE_TYPE.NO_COMP) &&
      device.rk === rk
    ) {
      const newGenSuctionObj = getNewSuctionObject(
        rk,
        name,
        advanceSg,
        insertTodeviceType,
        discoveryTableData,
        deviceConstraintsData,
        t,
      );
      attachSuction(i, newGenSuctionObj, discoveryTableData);
      return;
    }
  }
};

const attachSuction = (
  index: number,
  suctionObject: DiscoveryTableData,
  discoveryTableData: DiscoveryTableData[],
) => {
  let nextIndex = 0;
  let suctionFound = true;
  nextIndex = index + 1;
  if (nextIndex < discoveryTableData.length) {
    if (discoveryTableData[nextIndex].deviceType !== suctionObject.deviceType) {
      discoveryTableData.splice(index + 1, 0, suctionObject);
      return;
    }
    while (suctionFound) {
      if (nextIndex >= discoveryTableData.length) {
        break;
      }
      if (
        discoveryTableData[nextIndex].deviceType === suctionObject.deviceType
      ) {
        nextIndex++;
      } else {
        suctionFound = false;
        break;
      }
    }
    discoveryTableData.splice(nextIndex, 0, suctionObject);
    return;
  }
  discoveryTableData.splice(nextIndex, 0, suctionObject);
};

const getNewSuctionObject = (
  rk: number,
  name: string,
  advanceSg: boolean,
  insertTodeviceType: string,
  discoveryTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
) => {
  const deviceType =
    insertTodeviceType === DEVICE_TYPE.GEN_PACK
      ? DEVICE_TYPE.GEN_SUCT
      : insertTodeviceType === DEVICE_TYPE.RACK
      ? DEVICE_TYPE.RACKSUCT
      : insertTodeviceType === DEVICE_TYPE.NO_COMP
      ? DEVICE_TYPE.NO_COMP_SUCT
      : null;
  const discoveryTableDataObj: DiscoveryTableData = {
    deviceId: getDeviceIndex(discoveryTableData) + 1,
    removed: false,
    category: DEVICE_CATEGORY.PACK,
    deviceType,
    type: getDescriptionBasedOnType(deviceType, t),
    name,
    file: '',
    version: '',
    device: '',
    model_idx: 0,
    ip: '',
    address:
      insertTodeviceType === DEVICE_TYPE.GEN_PACK
        ? getPackAddressBasedOnRk(rk, discoveryTableData)
        : '-',
    rk,
    sg: !advanceSg
      ? getNextSuctionGroupNumber(discoveryTableData) - 1
      : getNextSuctionGroupNumber(discoveryTableData),
    dim1: '0',
    dim2: '0',
    dim3: '0',
    num_sg: 1,
    cfg: '0',
    online: '0',
    status: DEVICE_STATUS.RED,
    case_type: t('t520'),
    deviceBus: t('t490'),
    mx_naddr: deviceConstraintsData.max_naddr,
    assigned: false,
  };
  return discoveryTableDataObj;
};

const getPackAddressBasedOnRk = (
  rk: number,
  discoveryTableData: DiscoveryTableData[],
): string => {
  return discoveryTableData?.find(device => {
    return device?.deviceType === DEVICE_TYPE.GEN_PACK && device?.rk === rk;
  })?.address;
};

const getDeviceTypeToInsert = (
  discoveryTableData: DiscoveryTableData[],
  rk: number,
): string => {
  let insertTodeviceType: string = '';
  for (const device of discoveryTableData) {
    if (device.rk === rk) {
      switch (device.deviceType) {
        case DEVICE_TYPE.GEN_PACK:
          insertTodeviceType = DEVICE_TYPE.GEN_PACK;
          break;
        case DEVICE_TYPE.RACK:
          insertTodeviceType = DEVICE_TYPE.RACK;
          break;
        case DEVICE_TYPE.NO_COMP:
          insertTodeviceType = DEVICE_TYPE.NO_COMP;
          break;
        default:
          break;
      }
      break;
    }
  }
  return insertTodeviceType;
};

export { copyCompressor, insertSuctionGroup };
