import { _ as _objectSpread2 } from '../../../../../_rollupPluginBabelHelpers-4e4a2de3.js';
import '@danfoss/etui-sm-xml';
import '@danfoss/etui-core';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-type.js';
import '../../../../../utils/get-software-version-prefix.js';
import '../../../../../utils/compare-software-versions.js';
import '../../../../../utils/get-version-from-filename.js';
import '../../../../../utils/get-is-misc-device.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../context/SoftwareUpdateContext.js';
import '../context/hooks/use-multi-unit-update.js';
import '../../../../../use-update-type-values-914533e0.js';
import { useSoftwareUpdate } from '../context/hooks/use-software-update.js';
import 'moment';
import JSZip from 'jszip';
import path from 'path';
import { fetchEdfFilesSM800 } from '../actions/fetch-edf-files-sm800.js';
import { getCombinedEdfFilesListSM800 } from '../utils/get-combined-edf-files-list-sm800.js';

function useEdfUpdateSM800(url, user, unit) {
  const [parsedEdfFiles, setParsedEdfFiles] = React.useState(null);
  const [sm800EdfFiles, setSm800EdfFiles] = React.useState(null);
  const [errorLoading, setErrorLoading] = React.useState(null);
  const [parseSuccess, setParseSuccess] = React.useState(false);
  const [updateUploadProgress, setUpdateUploadProgress] = React.useState(0);
  const {
    file,
    setSm800InstallFiles
  } = useSoftwareUpdate();
  const {
    t
  } = useTranslation();
  const handleSetProgressSuccess = progress => {
    setUpdateUploadProgress(progress);
  };
  const b64Files = [];
  function updateFiles(zipFile) {
    const updatedFileObj = Object.values(zipFile.files).map(eachFile => {
      const fileName = extractFileName(eachFile.name);
      eachFile = _objectSpread2(_objectSpread2({}, eachFile), {}, {
        name: fileName
      });
      return eachFile;
    }).filter(updatedFile => {
      return updatedFile.dir === false && (updatedFile.name.endsWith('.ed3') || updatedFile.name.endsWith('.ed4'));
    });
    return updatedFileObj;
  }
  async function parseFiles() {
    const zip = new JSZip();
    const zipFile = await zip.loadAsync(file);
    await parseToB64(zipFile);
    const updatedFileObj = updateFiles(zipFile);
    setSm800InstallFiles(b64Files);
    setParsedEdfFiles(updatedFileObj);
    setParseSuccess(true);
    setUpdateUploadProgress(80);
  }
  function readFileAsync(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  function extractFileName(fileName) {
    return fileName.substring(fileName.lastIndexOf('/') + 1, fileName.length);
  }
  async function parseToB64(zipFile) {
    await Object.values(zipFile.files).reduce(async (promise, eachFile) => {
      await promise;
      const zblob = await (eachFile === null || eachFile === void 0 ? void 0 : eachFile.async('blob'));
      const base64 = await readFileAsync(zblob);
      const b64 = base64.split('base64,').pop();
      const size = b64.length;
      const fileName = extractFileName(eachFile.name);
      const obj = {};
      obj.name = fileName;
      obj.b64 = b64;
      obj.size = size;
      obj.offset = eachFile._data.uncompressedSize;
      b64Files.push(obj);
    }, Promise.resolve());
  }
  async function readEdfFilesFromDirectory() {
    try {
      const response = await fetchEdfFilesSM800(url, user);
      const filesFetched = response.file;
      const filteredEdfFilesFromSM800 = filesFetched.filter(eachFetchedFile => path.extname(eachFetchedFile.filename) === '.ed3' || path.extname(eachFetchedFile.filename) === '.ed4');
      setSm800EdfFiles(filteredEdfFilesFromSM800);
    } catch (e) {
      setErrorLoading(new Error(t('t3239')));
    }
  }
  const combinedFilesList = React.useMemo(() => getCombinedEdfFilesListSM800(parsedEdfFiles, sm800EdfFiles, handleSetProgressSuccess, unit), [sm800EdfFiles, parsedEdfFiles]);
  return {
    parseFiles,
    readEdfFilesFromDirectory,
    parseSuccess,
    combinedFilesList,
    sm800EdfFiles,
    parsedEdfFiles,
    errorLoading,
    updateUploadProgress
  };
}

export { useEdfUpdateSM800 };
