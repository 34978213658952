import '../../constants/session-storage-constants.js';
import { XML_ACTION } from '../../constants/xml-action-constant.js';
import '../../constants/xml-tag-constant.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import '../../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../../fetch-xml/fetch-xml-data.js';

async function fetchDeviceAlarmSummary(url, user, device) {
  let bpidx = 0;
  if (parseInt(device.bpidx, 10) > 0) {
    bpidx = parseInt(device.bpidx, 10) - 1;
  }
  return fetchXMLData({
    url,
    attributes: {
      arg1: device.arg1,
      arg2: device.arg2,
      arg3: device.arg3,
      bpidx: bpidx.toString(),
      node: device.node,
      nodetype: device.nodetype,
      stype: device.stype,
      mod: device.mod,
      point: device.point,
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_DEVICE_ALARMS,
      storeview_only: '1',
      version: 'C'
    }
  });
}

export { fetchDeviceAlarmSummary };
