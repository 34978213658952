import * as React from 'react';
import {
  getUnitUrl,
  fetchLayoutListByUnit,
  XML_REFRIG_COPY_TAB_INDEX,
  ConfigurationListItem,
  ConfigurationListDataArray,
  readFileAsync,
  parseXMLToJSON,
  XML_ACTION,
  isXmlError,
  fetchXMLMultipleFileData,
  REGEX_PATTERNS,
} from '@danfoss/etui-sm-xml';
import {
  useXmlResource,
  useAuth,
  ContentSpinner,
  EmptyState,
} from '@danfoss/etui-sm/';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { Notification } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { writeConfigurationListItem } from '../../../../../Configuration/actions';
import { withErrorHandled } from '../../../../../Configuration/utils';
import { useSaveWithRevalidate } from '../../../../../Configuration/components/ConfigurationItemContentList/hooks/useSaveWithRevalidate';
import { useRefrigCopy } from '../../context';
import {
  CopySourceControllerFileJson,
  CopySourceLoadFileResp,
  COPY_LIST_COUNT,
} from '../../types/RefrigCopy.types';
import {
  checkOnlineSourceControllersAvailable,
  filterAndUpdateOnlineSourceControllers,
} from '../../actions';
import { CopyItemVirtualizedList as VirtualizedList } from './CopyItemVirtualizedList';

interface CopyItemContentListProps {
  tableAddress: string;
}

function CopyItemContentList({ tableAddress }: CopyItemContentListProps) {
  const { user } = useAuth();
  const { url: xmlBackendURL } = useXmlResource();
  const { t } = useTranslation();
  const theme = useTheme();
  const { unit } = useRefrigCopy();
  const menuId = tableAddress;
  const {
    networkListData,
    isCopyControllerSelected,
    isCopyControllerLoading,
    isLoadingConfigFile,
    isHvac,
    isBackToSourceController,
    selectedDeviceBpidx,
    setIsCopyControllerSelected,
    setNetworkListData,
    setIsCopyControllerLoading,
    setIsLoadingConfigFile,
    setIsAK2,
    setSelectedDeviceBpidx,
  } = useRefrigCopy();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isOnlineControllersAvailable, setIsOnlineControllersAvailable] =
    React.useState<boolean>(true);
  const [isPageLoading, setIsPageLoading] = React.useState<boolean>(true);

  const fetch = async (index: string) => {
    const { items, listcount } = await fetchLayoutListByUnit([
      getUnitUrl(xmlBackendURL, unit),
      unit,
      tableAddress,
      index,
      user,
    ]);
    if (isBackToSourceController) {
      setIsPageLoading(false);
    }
    const listItems = items?.l;

    if (
      index === XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER &&
      listItems &&
      listcount === COPY_LIST_COUNT.TWO
    ) {
      await filterAndUpdateOnlineSourceControllers(
        xmlBackendURL,
        user,
        listItems,
        isHvac,
      );
      const isAvailable = checkOnlineSourceControllersAvailable(listItems);
      setIsOnlineControllersAvailable(isAvailable);
      setIsPageLoading(false);
      if (isAvailable) {
        setNetworkListData(listItems);
      }
    }
  };

  React.useEffect(() => {
    const fetchIntroduction = async () => {
      fetch(XML_REFRIG_COPY_TAB_INDEX.INTRODUCTION);
    };
    const fetchSourceController = async () => {
      fetch(XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER);
    };
    setIsPageLoading(true);
    if (!isCopyControllerSelected) {
      fetchIntroduction();
    }
    fetchSourceController();
  }, []);

  const handleSave = React.useCallback(
    async (listItem, { value, ival, fval = null } = {}) => {
      const response = await withErrorHandled(
        t,
        theme,
        writeConfigurationListItem,
      )(
        getUnitUrl(xmlBackendURL, unit),
        user,
        null,
        menuId,
        null,
        0,
        0,
        listItem,
        value,
        ival,
        fval,
      );
      return response;
    },

    [unit, user, xmlBackendURL],
  );

  const revalidate = async (isLoadControllerFileRevalidation?: boolean) => {
    const result = await fetchLayoutListByUnit([
      getUnitUrl(xmlBackendURL, unit),
      unit,
      tableAddress,
      '1',
      user,
    ]);
    setIsAK2(result?.is_ak2 === '1');
    if (result?.items?.l) {
      await filterAndUpdateOnlineSourceControllers(
        xmlBackendURL,
        user,
        result?.items?.l,
        isHvac,
      );
      setNetworkListData(result?.items?.l);
      if (isLoadControllerFileRevalidation && result?.is_ak2 === '1') {
        setIsLoadingConfigFile(false);
      } else {
        await checkForRevalidate(result.listcount);
      }
      if (
        result.items.l.length > 1 &&
        result.items.l[1].value === result.items.l[1].listdata[0]._
      ) {
        setIsCopyControllerSelected(false);
      }

      const deviceListItem: ConfigurationListItem = result.items.l[1];

      const devices: ConfigurationListDataArray = Array.isArray(
        deviceListItem.listdata,
      )
        ? deviceListItem.listdata
        : [];

      const selectedDevice = devices.find(device =>
        device._.includes(deviceListItem.value),
      );
      const selectedDeviceBpidx = selectedDevice.datavalue;
      setSelectedDeviceBpidx(selectedDeviceBpidx);
    }
  };

  const checkForRevalidate = async (listcount: string) => {
    const isLoading =
      listcount !== COPY_LIST_COUNT.FOUR &&
      listcount !== COPY_LIST_COUNT.TWO &&
      listcount !== COPY_LIST_COUNT.FIVE;
    setIsCopyControllerLoading(isLoading);
    if (isLoading) {
      setIsCopyControllerSelected(true);
      await new Promise(() => {
        revalidate();
      });
    } else {
      setIsLoadingConfigFile(false);
    }
  };

  const { execute: executeOnHandleSave } = useSaveWithRevalidate(
    handleSave,
    revalidate,
    1000,
    false,
  );

  const showNotification = (description: string) => {
    Notification.error({
      message: t('t17'),
      description,
      duration: 5,
      theme,
    });
  };

  const handleBrowse = async (file: File, item: ConfigurationListItem) => {
    if (file) {
      try {
        setIsLoadingConfigFile(true);
        setIsLoading(true);
        if (!file.name.includes('.cfz')) {
          setIsLoadingConfigFile(false);
          setIsLoading(false);
          return showNotification(`${file.name} ${t('t3205')}`);
        }
        const fileBase64 = await readFileAsync(file);
        const b64 = (fileBase64 as string).split('base64,').pop();
        const fileXml = atob(b64);
        const fileJson: CopySourceControllerFileJson = await parseXMLToJSON(
          fileXml,
        );
        const selectedControllerModelName =
          getSelectedControllerModelName(item);
        if (selectedControllerModelName !== fileJson.resp.model_name.trim()) {
          setIsLoadingConfigFile(false);
          setIsLoading(false);
          return showNotification(
            `${t('t3431')} ${selectedControllerModelName}`,
          );
        }
        const {
          num_bytes,
          num_lines,
          enc_bytes: size,
          model_name: name,
          device_id: id,
          b64: b64Array,
          lines = +num_lines,
        } = fileJson.resp;
        const from: string = file.name;
        const bpidx: string = selectedDeviceBpidx;
        let b64Lines = '';
        b64Array.forEach((b64, index) => {
          if (
            index < lines &&
            (b64.includes('&') || b64.includes('<') || b64.includes('>'))
          ) {
            b64Lines += `<b64>${encodeURI(b64)}</b64>`;
          } else {
            b64Lines += `<b64>${b64}</b64>`;
          }
        });
        await loadControllerFile(
          bpidx,
          size,
          name,
          from,
          id,
          num_bytes,
          b64Lines,
        );
      } catch (e) {
        setIsLoadingConfigFile(false);
        setIsLoading(false);
        showNotification(t('t3432'));
      }
    }
  };

  const loadControllerFile = async (
    bpidx: string,
    size: string,
    name: string,
    from: string,
    id: string,
    num_bytes: string,
    b64Lines: string,
  ) => {
    try {
      const response: CopySourceLoadFileResp = await fetchXMLMultipleFileData({
        url: xmlBackendURL,
        attributes: {
          user: user.user,
          password: user.password,
          lang: user.language,
          action: XML_ACTION.LOAD_CONFIG_FILE,
          bpidx,
          size,
          name,
          from,
          id,
          offset: num_bytes,
        },
        multipleB64: b64Lines,
      });
      if (response.status === '1') {
        setIsLoading(false);
        await revalidate(true);
      }
    } catch (e) {
      setIsLoadingConfigFile(false);
      setIsLoading(false);
      if (isXmlError(e)) {
        showNotification(e.message);
      }
    }
  };

  const getSelectedControllerModelName = (item: ConfigurationListItem) => {
    const bnum: number = parseInt(item.bnum, 10);
    const listItems = item?.listdata as ConfigurationListDataArray;
    let selectedController = listItems.find(
      listItem => parseInt(listItem.datavalue, 10) === bnum,
    )._;
    if (selectedController) {
      selectedController = selectedController
        .replace(REGEX_PATTERNS.SPACE, ' ')
        .trim();
      selectedController = selectedController
        .substring(
          selectedController.lastIndexOf(' '),
          selectedController.length,
        )
        .trim();
    }
    return selectedController;
  };
  const isCopyLoading: boolean = isCopyControllerLoading || isLoadingConfigFile;
  return (
    <>
      {isLoading && <ContentSpinner size={1} transparent={true} />}
      {isPageLoading ? (
        <ContentSpinner size={1} transparent={true} />
      ) : isOnlineControllersAvailable ? (
        <VirtualizedList
          list={networkListData}
          tabIsEditable={true}
          onSave={executeOnHandleSave}
          isCopyControllerSelected={isCopyControllerSelected}
          isCopyControllerLoading={isCopyControllerLoading}
          isCopyLoading={isCopyLoading}
          handleBrowse={handleBrowse}
          isWizards={true}
        />
      ) : (
        <Div testId="online-not-available" style={{ marginTop: '200px' }}>
          <EmptyState title={t('t3490')} />
        </Div>
      )}
    </>
  );
}

export { CopyItemContentList };
