import * as React from 'react';
import { Compressor } from '@danfoss/etui-graphics';
import { Div } from '@danfoss/etui-system-elements';
import { Button } from '@danfoss/etui-core';
import { StagingPatternTableData } from './types';
import { ConfigurationListItemEditStagingPatternUnloaders } from './ConfigurationListItemEditStagingPatternUnloaders';

export interface ConfigurationListItemEditStagingPatternCompressorsProps {
  stagingPattern: StagingPatternTableData;
  rowIndex: number;
  handleCompressorClick: (rowIndex: number, compIndex: number) => void;
  handleUnloaderClick: (
    rowIndex: number,
    compIndex: number,
    unloaderIndex: number,
  ) => void;
}

export const ConfigurationListItemEditStagingPatternCompressors = ({
  stagingPattern,
  rowIndex,
  handleCompressorClick,
  handleUnloaderClick,
}: ConfigurationListItemEditStagingPatternCompressorsProps) => {
  return (
    <>
      {stagingPattern.compressorUnloaderVisibiltyStatus.map(
        (visibility, compIndex) => {
          const visibilities: boolean[] = Object.values(
            visibility.unloadersVisibility,
          ).filter(value => value === true);
          const statuses: boolean[] = Object.values(
            visibility.compressorUnloadersStatus,
          ).slice(1);
          return (
            <Div
              testId={`compressor-unloader-${compIndex}`}
              display="flex"
              width="100px"
            >
              <Div testId={`compressor-${compIndex}`} mr="-55px">
                <Button
                  testId={`compressor-button-${compIndex}`}
                  styles={{
                    root: { width: '30%', ml: '20px' },
                  }}
                  onClick={() => {
                    handleCompressorClick(rowIndex, compIndex);
                  }}
                >
                  <Compressor height="80px" opacity={1} />
                </Button>
              </Div>
              <Div
                testId={`unloader-${compIndex}`}
                ml="-40px"
                mt={
                  visibilities.length === 4
                    ? '4px'
                    : visibilities.length === 3
                    ? '24px'
                    : visibilities.length === 2
                    ? '44px'
                    : '64px'
                }
              >
                <ConfigurationListItemEditStagingPatternUnloaders
                  visibilities={visibilities}
                  statuses={statuses}
                  rowIndex={rowIndex}
                  compIndex={compIndex}
                  handleUnloaderClick={handleUnloaderClick}
                />
              </Div>
            </Div>
          );
        },
      )}
    </>
  );
};
