/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
/* eslint-disable  @typescript-eslint/no-unused-expressions */
import * as React from 'react';
import _flatMap from 'lodash.flatmapdeep';
import {
  useXmlResource,
  useUnit,
  useAuth,
  SplitView,
  useMeasurementUnits,
  Measurement,
  getIsMatchedSoftwareVersion,
  SOFTWARE_VERSION_PREFIX,
  useAlarm,
} from '@danfoss/etui-sm';
import { Page } from 'components/Page';
import { Device, HistoryCfg, isXmlError, XmlError } from '@danfoss/etui-sm-xml';
import { fetchGroupedDevicesByDeviceTypes } from 'actions';
import { getUnitByDevice, getDeviceTypesByUnits } from 'utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { IconType, Notification } from '@danfoss/etui-core';
import { usePrevious, useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import { getNestedItemById } from 'components/DeviceBasedMenu/utils';
import { DeviceType, DeviceGrouped } from '@danfoss/etui-sm/types';
import ReactDOM from 'react-dom';
import { withErrorHandler } from 'utils/with-error-handler';
import { getErrorCodeTranslator } from 'utils/error-code-to-t-code';
import { color } from 'utils/color';
import { getHistoryUnitsConfig } from 'utils/get-history-units-config';
import {
  fetchDeviceParams,
  runChecksForQueries,
  startHistoryQueries,
} from './utils/history-actions';
import { processData } from './utils/history-parsing';
import {
  ChartData,
  DateRange,
  LegendConfig,
  LegendMap,
} from './HistoryPage.types';
import { addFetchedItems } from './utils/history-utils';
import { HistoryPageLeftMenu } from './OldHistoryPageLeftMenu';
import { HistoryPageContent } from './OldHistoryPageContent';
import { filterByDateRange } from './utils/history-filter-chart-data';
import { useIsMounted } from './hooks/use-is-mounted';
import { flattenDevices } from './utils/flatten-devices';

function initDateRange() {
  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);
  const tenHoursAgo = new Date(now);
  tenHoursAgo.setHours(tenHoursAgo.getHours() - 12);
  return {
    startDate: tenHoursAgo,
    endDate: now,
  };
}

function getInitDateRange(start: string, end: string) {
  if (start && end) {
    return { startDate: new Date(+start), endDate: new Date(+end) };
  }
  return initDateRange();
}

type ParamsMap = Map<string, HistoryCfg>;

const START_TIME = 's';
const END_TIME = 'e';
const HISTORY = 'h';
const PARAM_LOADING_BATCH_SIZE = 10;

function* arrayQueue(arr: any[], n: number) {
  for (let i = n; i < arr.length; i++) {
    yield arr[i];
  }
}

export default function HistoryPage() {
  const q = useQuery();
  const { t } = useTranslation();
  const theme = useTheme();
  const { push } = useHistory();
  const { url } = useXmlResource();
  const { user } = useAuth();
  const { setShouldRequest: setShouldRequestAlarms } = useAlarm();
  const alreadyFetched = React.useRef(new Set()); // keep track af fetched history config to avoid unnecessary refetch
  const rawChartData = React.useRef<ArrayBuffer[]>(null);
  const [group, setGroup] = React.useState('time-range');
  const [allParams, setAllParams] = React.useState<ParamsMap>(new Map());
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [loadProgress, setLoadProgress] = React.useState(100);
  const [formats, setFormats] = React.useState<Measurement>(null);
  const [chartData, setChartData] = React.useState<ChartData[]>(null);
  const [legendMap, setLegendMap] = React.useState<LegendMap>(null);
  const measurements = useMeasurementUnits();
  const [shouldDrawFromQuery, setShouldDrawFromQuery] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>(
    getInitDateRange(q.get(START_TIME), q.get(END_TIME)),
  );
  const {
    units,
    loadState: unitLoadState,
    loadError: unitLoadError,
    dispatch: unitDispatch,
    getFirstValidUrl,
  } = useUnit();

  const isLoading = unitLoadState !== 'done';
  const failedHistoryParamsCount = React.useRef(0);
  const [isAllHistoryParamsLoaded, setIsAllHistoryParamsLoaded] =
    React.useState(false);
  const isMounted = useIsMounted();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    !isLoading && unitDispatch({ type: 'RECONNECT' });
  }, []);

  React.useEffect(() => {
    units.length &&
      fetchGroupedDevicesByDeviceTypes([url, user, units]).then(devices =>
        updateDeviceIds(devices),
      );
  }, [units.length]);

  const updateDeviceIds = devices => {
    if (devices) {
      const devicesMap = Object.keys(devices).reduce((map, deviceType) => {
        const deviceObj = devices[deviceType].map(device => {
          return { ...device, id: `${device.deviceType}-${device.id}` };
        });
        return { ...map, [deviceType]: deviceObj };
      }, {});

      setData(devicesMap);
    }
  };

  const menuEntries = ['time-range', ...getDeviceTypesByUnits(units)];
  const prevData = usePrevious(data);
  const shouldFetchHistoryByResource = getIsMatchedSoftwareVersion(units, [
    SOFTWARE_VERSION_PREFIX.G03,
    SOFTWARE_VERSION_PREFIX.G08,
  ]);

  const miscCategories = data?.misc.map(device => device.id) || [];

  function onAllHistoryParamsLoad() {
    setIsAllHistoryParamsLoaded(true);
    if (failedHistoryParamsCount.current) {
      notify(
        t('t225'),
        t('t2257', { n: `${failedHistoryParamsCount.current}` }),
        'warning',
      );
    }
  }
  /**
   * Fetch history params for all devices
   */
  React.useEffect(() => {
    if (prevData) {
      // do this only on initial device load
      return;
    }
    const allDevices = flattenDevices(data);
    const firstBatchOfDevices = allDevices.slice(0, PARAM_LOADING_BATCH_SIZE);
    const iterator = arrayQueue(allDevices, PARAM_LOADING_BATCH_SIZE);
    let hasShownWarning = false;

    function fetchParamsForNextDevice(iter, onEndCb) {
      const { value, done } = iter.next();
      if (hasShownWarning) {
        return;
      }
      if (done) {
        onEndCb();
        hasShownWarning = true;
        return;
      }
      fetchParams(value).finally(() => {
        if (isMounted) {
          fetchParamsForNextDevice(iter, onEndCb);
        }
      });
    }

    firstBatchOfDevices.forEach(device => {
      fetchParams(device as any).finally(() => {
        isMounted && fetchParamsForNextDevice(iterator, onAllHistoryParamsLoad);
      });
    });
  }, [data, prevData]);

  function setQueryUrl() {
    q.set(START_TIME, `${+dateRange.startDate}`);
    q.set(END_TIME, `${+dateRange.endDate}`);
    q.set(HISTORY, selectedHistoryObjects.map(({ id }) => id).join('*'));

    push({ search: q.toString() });
  }

  function notify(
    message: string,
    description: string,
    type: IconType = 'info',
  ) {
    Notification.open({
      message,
      description,
      duration: 3,
      type,
      theme,
    });
  }

  function getHistoryErrorDescription(e: Error | XmlError) {
    return isXmlError(e)
      ? e.displayError(getErrorCodeTranslator(t))
      : e.message || t('t2998');
  }

  function handleHistoryError(e: Error) {
    notify(t('t17'), getHistoryErrorDescription(e), 'error');
  }
  /**
   * draw charts if configuration for it was set through initial url by query params
   */
  React.useEffect(() => {
    const queryUrlHistoryIds = (q.get(HISTORY) || '').split('*');
    if (data && !prevData && queryUrlHistoryIds?.[0]) {
      const [, ...deviceTypes] = menuEntries;
      // get uniq device ids to which history objects from URL belongs
      const devicesIds = [
        // history id is `${deviceId}_${index}HY
        ...new Set(queryUrlHistoryIds.map(id => id.split('_')[0])),
      ];
      // find devices by their ids
      const devices = _flatMap(deviceTypes, type =>
        devicesIds.map(id => getNestedItemById(data[type], id)).filter(Boolean),
      );
      if (devicesIds.length > devices.length) {
        notify(t('t2112'), t('t2113'), 'warning');
      }
      Promise.all(
        devices.map(d => {
          const unit = getUnitByDevice(units, d);
          const unitUrl = getFirstValidUrl(unit);
          return fetchDeviceParams(unitUrl, user, unit, d).then(
            value => ({ status: 'fulfilled', value }),
            reason => ({ status: 'rejected', reason }),
          );
        }),
      ).then(results => {
        const processed = results.reduce<{
          data: { [key in DeviceType]?: DeviceGrouped[] };
          allParams: HistoryCfg[];
        }>(
          // new tree data (devices + history objects)
          (acc, { status, value, reason }, i) => {
            if (status === 'fulfilled') {
              const device = devices[i];
              alreadyFetched.current?.add(device.id);
              acc.allParams.push(...value);
              acc.data[device.deviceType] = addFetchedItems(
                acc.data[device.deviceType],
                device,
                value,
              );
            }
            if (status === 'rejected') {
              handleHistoryError(reason);
            }
            return acc;
          },
          { data: { ...data }, allParams: [] },
        );

        ReactDOM.unstable_batchedUpdates(() => {
          const paramsToCheck = processed.allParams
            .map(({ id }) => id)
            .filter(id => queryUrlHistoryIds.includes(id));
          // mutate(processed.data, false);
          setAllParams(new Map(processed.allParams.map(p => [p.id, p])));
          setCheckedItems(paramsToCheck);
          if (paramsToCheck.length < queryUrlHistoryIds.length) {
            notify(t('t2114'), t('t2115'), 'warning');
          }
        });

        setShouldDrawFromQuery(true);
      });
    }
  }, [data, prevData, menuEntries]);

  const fetchParams = async (
    device: Device & { id: string; deviceType: string },
  ) => {
    if (miscCategories.includes(device.id)) return;

    const unit = getUnitByDevice(units, device);
    const unitUrl = getFirstValidUrl(unit);
    let params;
    try {
      params = await withErrorHandler(fetchDeviceParams, handleHistoryError, {
        shouldPropagate: true,
      })(unitUrl, user, unit, device);
      alreadyFetched.current?.add(device.id);
      onDataChange(device, params);
      setAllParams(prevParams => {
        const nextParams = new Map(prevParams);
        params.forEach(param => {
          nextParams.set(param.id, param);
        });
        return nextParams;
      });
    } catch (err) {
      failedHistoryParamsCount.current++;
    }
  };

  const selectedHistoryObjects = checkedItems
    .map(id => allParams.get(id))
    .filter(Boolean);

  React.useEffect(() => {
    if (shouldDrawFromQuery && selectedHistoryObjects?.length) {
      handleStartHistoryQuerying();
    }
    return () => shouldDrawFromQuery && setShouldDrawFromQuery(false);
  }, [shouldDrawFromQuery]);

  const handleStartHistoryQuerying = async () => {
    setShouldRequestAlarms(false);
    setQueryUrl();
    setChartData(null);
    setLoadProgress(0);
    const { mSecsOffset255toPC } = selectedHistoryObjects[0]; // TODO different units might have different offsets!
    const { startDate, endDate } = dateRange;
    try {
      const queries = await startHistoryQueries(
        user,
        Math.round((+startDate + mSecsOffset255toPC) / 1e3),
        Math.round((+endDate + mSecsOffset255toPC) / 1e3),
        selectedHistoryObjects,
      );
      const rawData = await runChecksForQueries(
        user,
        queries,
        setLoadProgress,
        shouldFetchHistoryByResource,
      );
      const colors = color();
      rawChartData.current = rawData;
      const processedData = processData(
        rawData,
        selectedHistoryObjects,
        getHistoryUnitsConfig(formats),
      );
      setLegendMap(
        selectedHistoryObjects.reduce<LegendMap>(
          (acc, { id }) => (
            (acc[id] = {
              isHidden: false,
              color: colors.next().value,
            }),
            acc
          ),
          {},
        ),
      );
      setChartData(
        shouldFetchHistoryByResource
          ? filterByDateRange(processedData, startDate, endDate)
          : processedData,
      );
    } catch (e) {
      handleHistoryError(e);
    } finally {
      setLoadProgress(100);
      setShouldRequestAlarms(true);
    }
  };

  const onDataChange = (device, params) => {
    setData(deviceData => ({
      ...deviceData,
      [device.deviceType]: addFetchedItems(
        deviceData[device.deviceType],
        device,
        params,
      ),
    }));
  };

  React.useEffect(() => {
    measurements && setFormats(measurements);
  }, [measurements]);

  const handleFormatChange = nextFormats => {
    setChartData(
      processData(
        rawChartData.current,
        selectedHistoryObjects,
        getHistoryUnitsConfig(nextFormats),
      ),
    );
    setFormats(nextFormats);
  };

  const handleChartsToDrawChange = (
    id: string,
    toChange: Partial<LegendConfig>,
  ) => {
    setLegendMap(prev => ({ ...prev, [id]: { ...prev[id], ...toChange } }));
  };

  const handleChartsDataClear = () => {
    setChartData(null);
    push({ search: '' });
  };

  return (
    <Page
      isFullPage={true}
      isBusy={isLoading}
      isError={Boolean(unitLoadError)}
      isReady={unitLoadState === 'done' && !!units.length}
    >
      <SplitView
        leftPane={
          <HistoryPageLeftMenu
            data={data}
            menuEntries={menuEntries}
            dateRange={dateRange}
            setDateRange={setDateRange}
            formats={formats}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            fetchParams={fetchParams}
            alreadyFetched={alreadyFetched.current}
            isAllHistoryParamsLoaded={isAllHistoryParamsLoaded}
            startHistoryQuery={handleStartHistoryQuerying}
            group={group}
            setGroup={setGroup}
            canDraw={
              selectedHistoryObjects.length &&
              dateRange.startDate < dateRange.endDate &&
              loadProgress === 100
            }
          />
        }
        rightPane={
          <HistoryPageContent
            dateRange={dateRange}
            formats={formats}
            loadProgress={loadProgress}
            indeterminateProgress={shouldFetchHistoryByResource}
            chartData={chartData}
            onFormatChange={handleFormatChange}
            legendMap={legendMap}
            onChartsToDrawChange={handleChartsToDrawChange}
            onChartsDataClear={handleChartsDataClear}
          />
        }
        visiblePaneOnSm={chartData ? 'right' : 'left'}
      />
    </Page>
  );
}
