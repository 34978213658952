import { ButtonProps } from '@danfoss/etui-core';
import * as React from 'react';
import {
  ConfigurationListDataArray,
  ConfigurationListItem,
  Unit,
} from '@danfoss/etui-sm-xml';
import {
  AlarmsTableData,
  CopyDevicesTableContent,
  CopyDevicesTableData,
  DevicesTableContent,
  DevicesTableData,
  GraphTableData,
  ParamTableData,
  RefrigCopySteps,
  SourceAlarmTableContent,
  SourceAlarmTableData,
  SourceAlarmTablePageContent,
  SourceGraphTableData,
  SourceGraphTablePageContent,
} from '../types/RefrigCopy.types';
import {
  AllPageType,
  RefrigCopyContext,
  RefrigCopyContextProps,
  RefrigCopyFlow,
} from './RefrigCopyContext';

interface RefrigCopyProviderProps {
  children: React.ReactNode;
}

function RefrigCopyProvider({ children }: RefrigCopyProviderProps) {
  const DEFAULT_FLOW = null;
  const DEFAULT_BUTTONS_BY_STEP = {};
  const DEFAULT_STEP = 1;

  const [selectedFlow, setSelectedFlow] =
    React.useState<RefrigCopyFlow>(DEFAULT_FLOW);

  const [unit, setSelectedUnit] = React.useState<Unit>();

  const [alarmListGroup, setAlarmListGroup] = React.useState<string[]>([]);

  const [buttonsByStep, setButtonsByStep] = React.useState<
    RefrigCopyContextProps['buttonsByStep']
  >(DEFAULT_BUTTONS_BY_STEP);

  const [step, setStep] =
    React.useState<RefrigCopyContextProps['step']>(DEFAULT_STEP);

  const [isOfflineProgramming, setIsOfflineProgramming] =
    React.useState<boolean>(true);

  const [activeStepNumber, setActiveStepNumber] = React.useState<number>(1);

  const setFlow = React.useCallback(
    (newFlow: RefrigCopyFlow) => {
      setSelectedFlow(newFlow);
    },
    [selectedFlow],
  );

  const setButtons = React.useCallback(
    (newButtons: ButtonProps[]) => {
      setButtonsByStep({ [step]: newButtons });
    },
    [step],
  );

  const [refrigSteps, setRefrigSteps] = React.useState<RefrigCopySteps[]>([]);

  const setUnit = React.useCallback(
    (unit: Unit) => {
      setSelectedUnit(unit);
    },
    [selectedFlow],
  );

  const [isTableRefreshing, setIsTableRefreshing] =
    React.useState<boolean>(false);

  const [networkListData, setNetworkListData] = React.useState([]);
  const [sourceGraphTableData, setSourceGraphTableData] =
    React.useState<SourceGraphTableData>();
  const [sourceAlarmTableData, setSourceAlarmTableData] =
    React.useState<SourceAlarmTableData>();
  const [page, setPage] = React.useState<string>('0');
  const [searchValue, setSearchValue] = React.useState<string>();
  const [searchMode, setSearchMode] = React.useState<boolean>(false);
  const [multipage, setMultipage] = React.useState<string>();
  const [pageValues, setPageValues] = React.useState<number[]>([]);
  const [allPageData, setAllPageData] = React.useState<AllPageType[]>();

  const [allPageSourceAlarmData, setAllPageSourceAlarmData] =
    React.useState<SourceAlarmTableContent[]>();
  const [isAllPageDataFetched, setIsAllPageDataFetched] =
    React.useState<boolean>(false);
  const [isPageDataFetched, setIsPageDataFetched] =
    React.useState<boolean>(false);

  const [paramTableData, setParamTableData] = React.useState<ParamTableData>();

  const [alarmsTableData, setAlarmsTableData] =
    React.useState<AlarmsTableData>();

  const [graphTableData, setGraphTableData] = React.useState<GraphTableData>();

  const handleOnSetSearchValue = (value: string) => {
    setSearchValue(value);
    setSearchMode(true);
  };
  const [sourceGraphTablePageContent, setSourceGraphTablePageContent] =
    React.useState<SourceGraphTablePageContent[]>([]);
  const [saveCompleted, setSaveCompleted] = React.useState<boolean>();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>();
  const [sourceAlarmTablePageContent, setSourceAlarmTablePageContent] =
    React.useState<SourceAlarmTablePageContent[]>([]);
  const [alarmLevelList, setAlarmLevelList] =
    React.useState<ConfigurationListDataArray>();
  const [enableAllAlarms, setEnableAllAlarms] = React.useState<boolean>(false);
  const [devicesTableContent, setDevicesTableContent] = React.useState<
    DevicesTableContent[]
  >([]);
  const [devicesTableData, setDevicesTableData] =
    React.useState<DevicesTableData>();
  const [devicesListItems, setDevicesListItems] =
    React.useState<ConfigurationListItem[]>();
  const [selectAll, setSelectAll] = React.useState<ConfigurationListItem[]>([]);
  const [copyDevicesListItems, setCopyDevicesListItems] =
    React.useState<ConfigurationListItem[]>();
  const [copyDevicesTableContent, setCopyDevicesTableContent] = React.useState<
    CopyDevicesTableContent[]
  >([]);
  const [copyDevicesTableData, setCopyDevicesTableData] =
    React.useState<CopyDevicesTableData>();
  const [isCopyDevicesDataFetched, setIsCopyDevicesDataFetched] =
    React.useState<boolean>(false);
  const [isCopyFinished, setIsCopyFinished] = React.useState<boolean>(false);
  const [isCopyControllerSelected, setIsCopyControllerSelected] =
    React.useState<boolean>(false);
  const [isCopyControllerLoading, setIsCopyControllerLoading] =
    React.useState<boolean>(false);
  const [isLoadingConfigFile, setIsLoadingConfigFile] =
    React.useState<boolean>(false);
  const [isEmptyCopyDevicesTableData, setIsEmptyCopyDevicesTableData] =
    React.useState<boolean>(false);

  const [isHvac, setIsHvac] = React.useState<boolean>(false);
  const [isBackToSourceController, setIsBackToSourceController] =
    React.useState<boolean>(false);
  const [isAK2, setIsAK2] = React.useState<boolean>(false);
  const [selectedDeviceBpidx, setSelectedDeviceBpidx] =
    React.useState<string>(null);

  return (
    <RefrigCopyContext.Provider
      value={{
        flow: selectedFlow,
        setFlow,
        alarmListGroup,
        setAlarmListGroup,
        setButtons,
        buttonsByStep,
        setStep,
        step,
        isOfflineProgramming,
        setIsOfflineProgramming,
        activeStepNumber,
        setActiveStepNumber,
        refrigSteps,
        setRefrigSteps,
        unit,
        setUnit,
        isTableRefreshing,
        setIsTableRefreshing,
        networkListData,
        setNetworkListData,
        sourceGraphTableData,
        setSourceGraphTableData,
        sourceAlarmTableData,
        setSourceAlarmTableData,
        page,
        setPage,
        searchValue,
        setSearchValue,
        onSetSearchValue: handleOnSetSearchValue,
        searchMode,
        setSearchMode,
        multipage,
        setMultipage,
        pageValues,
        setPageValues,
        allPageData,
        setAllPageData,
        allPageSourceAlarmData,
        setAllPageSourceAlarmData,
        isAllPageDataFetched,
        setIsAllPageDataFetched,
        sourceGraphTablePageContent,
        setSourceGraphTablePageContent,
        isPageDataFetched,
        setIsPageDataFetched,
        saveCompleted,
        setSaveCompleted,
        selectedRowIndex,
        setSelectedRowIndex,
        sourceAlarmTablePageContent,
        setSourceAlarmTablePageContent,
        alarmLevelList,
        setAlarmLevelList,
        enableAllAlarms,
        setEnableAllAlarms,
        devicesTableContent,
        setDevicesTableContent,
        devicesTableData,
        setDevicesTableData,
        devicesListItems,
        setDevicesListItems,
        selectAll,
        setSelectAll,
        copyDevicesListItems,
        setCopyDevicesListItems,
        copyDevicesTableContent,
        setCopyDevicesTableContent,
        copyDevicesTableData,
        setCopyDevicesTableData,
        isCopyDevicesDataFetched,
        setIsCopyDevicesDataFetched,
        isCopyFinished,
        setIsCopyFinished,
        isCopyControllerSelected,
        setIsCopyControllerSelected,
        isCopyControllerLoading,
        setIsCopyControllerLoading,
        isLoadingConfigFile,
        setIsLoadingConfigFile,
        paramTableData,
        setParamTableData,
        alarmsTableData,
        setAlarmsTableData,
        graphTableData,
        setGraphTableData,
        isEmptyCopyDevicesTableData,
        setIsEmptyCopyDevicesTableData,
        isHvac,
        setIsHvac,
        isBackToSourceController,
        setIsBackToSourceController,
        isAK2,
        setIsAK2,
        selectedDeviceBpidx,
        setSelectedDeviceBpidx,
      }}
    >
      {children}
    </RefrigCopyContext.Provider>
  );
}

export { RefrigCopyProvider };
