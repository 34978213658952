import '../../constants/session-storage-constants.js';
import { XML_ACTION } from '../../constants/xml-action-constant.js';
import { XML_TAG } from '../../constants/xml-tag-constant.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import '../../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../../fetch-xml/fetch-xml-data.js';

async function fetchParameterInfo(url, user, device_id, device, onlyWithTags) {
  if (onlyWithTags === void 0) {
    onlyWithTags = '0';
  }
  if (!device_id && device.type === 'METER') {
    const meterInfo = await fetchEnergyMeterDeviceId(url, user);
    const meterDevice = meterInfo.meter.filter(meterDevice => {
      return meterDevice.name === device.name;
    });
    const meterId = meterDevice[0].device_id;
    if (!meterId) {
      return null;
    }
    return fetchXMLData({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        action: XML_ACTION.READ_PARMETER_INFO,
        storeview_only: '1',
        version: 'C',
        device_id: meterId,
        tag: onlyWithTags
      }
    });
  }
  if (!device_id) {
    const modelName = device.modelname.substring(device.modelname.length - 4);
    const miscDeviceId = `${device.ordernum}_${modelName}`;
    return fetchXMLData({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        action: XML_ACTION.READ_PARMETER_INFO,
        storeview_only: '1',
        version: 'C',
        device_id: miscDeviceId,
        tag: onlyWithTags
      }
    });
  }
  return fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      action: XML_ACTION.READ_PARMETER_INFO,
      storeview_only: '1',
      version: 'C',
      device_id,
      tag: onlyWithTags
    }
  });
}
async function fetchTagParametersValuesOfDevice(url, user, subElementRequestItems) {
  return fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      action: XML_ACTION.READ_VAL,
      storeview_only: '1',
      version: 'C',
      eds_only: '1'
    },
    items: subElementRequestItems
  });
}
async function fetchEnergyMeterDeviceId(url, user) {
  return fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      storeview_only: '1',
      version: 'C',
      action: XML_ACTION.READ_METERS
    }
  });
}
const sortOutParams = paramList => {
  const tagParams = [];
  const params = [];
  for (const param of paramList) {
    if (param.tag) {
      tagParams.push(param);
    } else {
      params.push(param);
    }
  }
  return [tagParams, params];
};
const fetchTaggedParamValues = async (url, user, device, tagParams) => {
  const getSubElementRequestTags = params => params.map(param => formatSubElementsForTagsParamsInfo(device, param));
  const subElementRequestItems = getSubElementRequestTags(tagParams);
  const data = subElementRequestItems.length > 0 ? await fetchTagParametersValuesOfDevice(url, user, subElementRequestItems) : null;
  if (data) {
    var _data$val;
    data.val = data === null || data === void 0 ? void 0 : (_data$val = data.val) === null || _data$val === void 0 ? void 0 : _data$val.map(item => {
      item.value = item._;
      return item;
    });
  }
  return data;
};
const fetchCommonParamValues = async (url, user, device, params) => {
  var _data$val2;
  const getSubElementRequestTagsForCIDVID = params => params.map(param => formatSubElementsForCIDVIDParamsInfo(device, param));
  const subElementRequestItemsForCIDVID = getSubElementRequestTagsForCIDVID(params);
  const data = await fetchTagParametersValuesOfDevice(url, user, subElementRequestItemsForCIDVID);
  data.val = data === null || data === void 0 ? void 0 : (_data$val2 = data.val) === null || _data$val2 === void 0 ? void 0 : _data$val2.map(item => {
    item.value = item._;
    return item;
  });
  return data;
};
const fetchDeviceParameterValues = async (url, device, user) => {
  const {
    parms: {
      parm: allParams
    }
  } = await fetchParameterInfo(url, user, device.device_id, device);
  const [tagParams, params] = sortOutParams(allParams);
  const commonParamPromise = fetchCommonParamValues(url, user, device, params);
  const taggedParamPromise = fetchTaggedParamValues(url, user, device, tagParams);
  const [taggedParamValues, commonParamValues] = await Promise.all([taggedParamPromise || Promise.resolve(null), commonParamPromise]);
  return commonParamValues === null || commonParamValues === void 0 ? void 0 : commonParamValues.val.concat((taggedParamValues === null || taggedParamValues === void 0 ? void 0 : taggedParamValues.val) || []);
};
const formatSubElementsForTagsParamsInfo = (item, param) => {
  return {
    tag: XML_TAG.VAL,
    attributes: {
      nodetype: item.nodetype,
      node: item.node,
      tag: param.tag
    }
  };
};
const formatSubElementsForCIDVIDParamsInfo = (item, param) => {
  return {
    tag: XML_TAG.VAL,
    attributes: {
      nodetype: item.nodetype,
      node: item.node,
      cid: param.cid,
      vid: param.vid
    }
  };
};
async function fetchParameterValueByTags(_ref) {
  let [xmlBackendURL, device, user] = _ref;
  return fetchDeviceParameterValues(xmlBackendURL, device, user);
}

export { fetchEnergyMeterDeviceId, fetchParameterInfo, fetchParameterValueByTags, fetchTagParametersValuesOfDevice };
